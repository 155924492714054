import React from 'react';
import { Typography, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const propTypes = {
  small: PropTypes.boolean,
  removeBottomPadding: PropTypes.boolean,
  classes: PropTypes.string,
  className: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  noBorder: PropTypes.boolean,
};

const defaultProps = {
  small: false,
  removeBottomPadding: false,
  noBorder: false,
};

const styles = () => ({
  root: {},
});

function SubHeaderText({
  classes, className, small, removeBottomPadding, title, subtitle, noBorder,
}) {
  const titleComponent = small ? 'h2' : 'h3'  ;
  const subTitleSize = small ? 'text-16' : 'text-18';
  const gutterBottomClass = removeBottomPadding ? 'pb-0' : '';

  return (
    <div className={classNames(classes.root, 'py-20 pt-0 container', gutterBottomClass, className)}>
      {title
        && (
          <Typography component={titleComponent} variant="inherit" className="text-center color-666666 font-sans fontAgendaBold"
            dangerouslySetInnerHTML={{ __html: title}}
            >
          </Typography>
        )
      }
      {subtitle
        && (
          <Typography component="p" className={classNames(subTitleSize, 'pt-12 text-center font-sans sm:text-16 px-40')}
            dangerouslySetInnerHTML={{ __html: subtitle}}
          >
          </Typography>
        )
      }
    </div>
  );
}

SubHeaderText.propTypes = propTypes;
SubHeaderText.defaultProps = defaultProps;

export default withStyles(styles)(SubHeaderText);
