import React, { Component } from 'react';
import classNames from 'classnames';
import Chip from '@material-ui/core/Chip';
import { Hidden, Typography } from '@material-ui/core';
import _ from 'lodash';


class SelectMenuInputField extends Component {
  render() {
    const {
      input,
      selectedItems,
      getInputProps,
      removeItem,
      isOpen,
      inputValue,
    } = this.props;

    return (
      <div className="flex flex-wrap items-center sm:inline-block lg:flex">
        {selectedItems.length > 0
          ? (
            <div className={classNames('p-0')}>
              <Hidden smDown>
                <Chip
                  
                  label={selectedItems.length > 1 ? `(${selectedItems.length} items selected)` :  _.first(selectedItems).name.length > 25 ? `${_.first(selectedItems).name.slice(0, 25)} ...` : `${_.first(selectedItems).name.slice(0, 25)}`}
                  color="secondary"
                  classes={{ label: 'p-0 mr-4 text-14', root: 'h-auto' }}
                  className={classNames(
                    isOpen ? 'bg-transparent text-white'
                      : 'bg-transparent text-black'
                  )}
                />
              </Hidden>
              <Hidden mdUp>
                <Chip
                  //label={selectedItems.length > 1 ? `(${selectedItems.length} items selected)` : `(${_.first(selectedItems).name})`}
                  label={selectedItems.length > 1 ? `(${selectedItems.length} items selected)` :  _.first(selectedItems).name.length > 25 ? `${_.first(selectedItems).name.slice(0, 25)} ...` : `${_.first(selectedItems).name.slice(0, 25)}`}
                  color="secondary"
                  classes={{ label: 'p-0 mr-4 text-14', root: 'h-auto' }}
                  className={classNames(
                    isOpen ? 'bg-transparent text-white'
                      : 'bg-transparent text-black'
                  )}
                />
              </Hidden>
            </div>
          )
          : (
            <Typography
              className={classNames((isOpen ? 'text-white' : 'text-black'),
                'block lg:text-14 md:text-14 sm:text-14')}
            >
              <Hidden mdUp>
                {'(All)'}
              </Hidden>
              <Hidden smDown>
                {'All'}
              </Hidden>
            </Typography>
          )
        }
        <input
          autoFocus={false}
          {...getInputProps({
            ref: input,
            onKeyUp(event) {
              if (event.key === 'Backspace' && !inputValue) {
                removeItem(selectedItems[selectedItems.length - 1]);
              }
            },
          })}
          readOnly
          className={classNames('sm:hidden md:hidden lg:hidden xl:hidden cursor-pointer text-14 text-white bg-transparent')}
        />
      </div>
    );
  }
}

export default (SelectMenuInputField);
