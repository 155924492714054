import React from 'react';
import Utils from 'utils';
import { HomeConfig } from 'pages/home/config';
import { ReviewsConfig } from 'pages/reviews/config';
import { GuidesConfig } from 'pages/guides/config';
import { DealsConfig } from 'pages/deals/config';
import { AdvisorsConfig } from 'pages/advisors/config';
import { GetFeaturedConfig } from 'pages/getfeatured/config';
import { AboutConfig } from 'pages/about/config';
import { SearchConfig } from 'pages/search/config';
import { GourmetStoriesConfig } from 'pages/gourmetStories/config';
import { ApplyNowConfig } from 'pages/applynow/config';
import { WhatsOnConfig } from 'pages/whatson/config';
import { Error404 } from 'pages/404';

const routeConfigs = [
  GourmetStoriesConfig,
  ReviewsConfig,
  GuidesConfig,
  DealsConfig,
  AdvisorsConfig,
  GetFeaturedConfig,
  AboutConfig,
  SearchConfig,
  HomeConfig,
  ApplyNowConfig,
  WhatsOnConfig,
];

export const routes = [
  ...Utils.generateRoutesFromConfigs(routeConfigs),
  {
    component: () => <Error404 />,
  },
];
