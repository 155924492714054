import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  ListItemText, ListItem, Icon, IconButton,
} from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles/index';
import { Manager, Reference, Popper } from 'react-popper';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import _ from 'lodash';
import * as ReactDOM from 'react-dom';
import NavHorizontalItem from './NavHorizontalItem';
import NavHorizontalCollapse from './NavHorizontalCollapse';

const defaultProps = {};

const styles = () => ({
  root: {
    '&.level-0': {
      height: 56,
    },
  },
  children: {},
  popper: {
    zIndex: 999,
  },
  popperClose: {
    pointerEvents: 'none',
  },
});

class NavHorizontalGroup extends Component {
    state = {
      open: false,
    };

    handleToggle = _.debounce((open) => {
      const { open: openState } = this.state;
      if (openState === open) {
        return;
      }
      this.setState({ open });
    }, 150);

    render() {
      const { item: itemProp, nestedLevel, classes } = this.props;
      const { open } = this.state;

      return (
        <Manager>
          <Reference>
            {({ ref }) => (
              <div ref={ref}>
                <ListItem
                  button
                  className={classNames(classes.root, 'relative', `level-${nestedLevel}`)}
                  onMouseEnter={() => this.handleToggle(true)}
                  onMouseLeave={() => this.handleToggle(false)}
                  aria-owns={open ? 'menu-list-grow' : null}
                  aria-haspopup="true"
                >
                  {itemProp.icon && (
                    <Icon color="action" className="text-16 flex-no-shrink">{itemProp.icon}</Icon>
                  )}
                  <ListItemText className="list-item-text pr-0" primary={itemProp.title} classes={{ primary: 'text-14' }} />
                  {nestedLevel > 0 && (
                    <IconButton disableRipple className="w-16 h-16 ml-4">
                      <Icon className="text-16 arrow-icon">keyboard_arrow_right</Icon>
                    </IconButton>
                  )}
                </ListItem>
              </div>
            )}
          </Reference>
          {ReactDOM.createPortal(
            <Popper
              placement={nestedLevel === 0 ? 'bottom-start' : 'right'}
              eventsEnabled={open}
              positionFixed
            >
              {({
                ref, style, placement,
              }) => (
                <div ref={ref} style={style} data-placement={placement} className={classNames(classes.popper, { [classes.popperClose]: !open })}>
                  <Grow in={open} id="menu-list-grow" style={{ transformOrigin: '0 0 0' }}>
                    <Paper
                      onMouseEnter={() => this.handleToggle(true)}
                      onMouseLeave={() => this.handleToggle(false)}
                    >
                      {itemProp.children && (
                        <ul className={classNames(classes.children, 'pl-0')}>
                          {
                            itemProp.children.map(item => (
                              <React.Fragment key={item.id}>

                                {item.type === 'group' && (
                                  <NavHorizontalGroup item={item} nestedLevel={nestedLevel} />
                                )}

                                {item.type === 'collapse' && (
                                  <NavHorizontalCollapse item={item} nestedLevel={nestedLevel} />
                                )}

                                {item.type === 'item' && (
                                  <NavHorizontalItem item={item} nestedLevel={nestedLevel} />
                                )}
                              </React.Fragment>
                            ))
                          }
                        </ul>
                      )}
                    </Paper>
                  </Grow>
                </div>
              )}
            </Popper>,
            document.querySelector('#root')
          )}
        </Manager>
      );
    }
}


NavHorizontalGroup.propTypes = {
  nestedLevel: PropTypes.string,
  classes: PropTypes.string,
  item: PropTypes.shape(
    {
      id: PropTypes.string.isRequired,
      title: PropTypes.string,
      children: PropTypes.array,
    }
  ),
};
NavHorizontalGroup.defaultProps = defaultProps;

export default withStyles(styles, { withTheme: true })(withRouter((NavHorizontalGroup)));
