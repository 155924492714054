import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Icon, IconButton, ListItem, ListItemText,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles/index';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import { Manager, Reference, Popper } from 'react-popper';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import _ from 'lodash';
import * as ReactDOM from 'react-dom';
import NavHorizontalItem from './NavHorizontalItem';
import NavHorizontalGroup from './NavHorizontalGroup';

const defaultProps = {};

const styles = () => ({
  root: {},
  button: {
    minHeight: 38,
    '&.open': {
      backgroundColor: 'rgba(0,0,0,.08)',
    },
  },
  popper: {
    zIndex: 999,
  },
  popperClose: {
    pointerEvents: 'none',
  },
});

class NavHorizontalCollapse extends Component {
    state = {
      open: false,
    };

    handleToggle = _.debounce((open) => {
      const { open: openState } = this.state;
      if (openState === open) {
        return;
      }
      this.setState({ open });
    }, 150);

    render() {
      const { item: itemProp, nestedLevel, classes } = this.props;
      const { open } = this.state;

      return (
        <ul className={classNames(classes.root, 'relative pl-0')}>
          <Manager>
            <Reference>
              {({ ref }) => (
                <div ref={ref}>
                  <ListItem
                    button
                    onClick={this.handleClick}
                    className={classNames(classes.button, open && 'open')}
                    onMouseEnter={() => this.handleToggle(true)}
                    onMouseLeave={() => this.handleToggle(false)}
                    aria-owns={open ? 'menu-list-grow' : null}
                    aria-haspopup="true"
                  >
                    {itemProp.icon && (
                      <Icon color="action" className="text-16 flex-no-shrink">{itemProp.icon}</Icon>
                    )}
                    <ListItemText className="list-item-text pr-0" primary={itemProp.title} classes={{ primary: 'text-14' }} />
                    <IconButton disableRipple className="w-16 h-16 ml-4">
                      <Icon className="text-16 arrow-icon">keyboard_arrow_right</Icon>
                    </IconButton>
                  </ListItem>
                </div>
              )}
            </Reference>
            {ReactDOM.createPortal(
              <Popper
                placement="right"
                eventsEnabled={open}
                positionFixed
              >
                {({
                  ref, style, placement,
                }) => (
                  <div ref={ref} style={style} data-placement={placement} className={classNames(classes.popper, { [classes.popperClose]: !open })}>
                    <Grow in={open} id="menu-list-grow" style={{ transformOrigin: '0 0 0' }}>
                      <Paper
                        onMouseEnter={() => this.handleToggle(true)}
                        onMouseLeave={() => this.handleToggle(false)}
                      >
                        {itemProp.children && (
                          <ul className={classNames(classes.children, 'pl-0')}>
                            {
                              itemProp.children.map(item => (

                                <React.Fragment key={item.id}>

                                  {item.type === 'group' && (
                                    <NavHorizontalGroup item={item} nestedLevel={nestedLevel + 1} />
                                  )}

                                  {item.type === 'collapse' && (
                                    <NavHorizontalCollapse item={item} nestedLevel={nestedLevel + 1} />
                                  )}

                                  {item.type === 'item' && (
                                    <NavHorizontalItem item={item} nestedLevel={nestedLevel + 1} />
                                  )}

                                </React.Fragment>
                              ))
                            }
                          </ul>
                        )}
                      </Paper>
                    </Grow>
                  </div>
                )}
              </Popper>,
              document.querySelector('#root')
            )}
          </Manager>
        </ul>
      );
    }
}


NavHorizontalCollapse.propTypes = {
  nestedLevel: PropTypes.string,
  classes: PropTypes.string,
  item: PropTypes.shape(
    {
      id: PropTypes.string.isRequired,
      title: PropTypes.string,
      icon: PropTypes.string,
      children: PropTypes.array,
    }
  ),
};
NavHorizontalCollapse.defaultProps = defaultProps;

export default withStyles(styles, { withTheme: true })(withRouter((NavHorizontalCollapse)));
