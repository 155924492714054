import React, { Component } from 'react';
import { IconButton, withStyles } from '@material-ui/core';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Up } from 'grommet-icons';
import classNames from 'classnames';
import { SpringSystem, MathUtil } from 'rebound';
// import { verticalScroll } from '../Article/AnimationScroll';
import { article } from '../Article/Page';

const styles = theme => ({
  button :{
    padding: 0
  },
  wrapper: {
    position: 'fixed',
    bottom: '0px',
    right: 0,
    zIndex: 1390,
    width: '40px',
    height: '40px',
  },
});

class BackToTop extends Component {
  constructor(props, ...rest) {
    super(props, ...rest);
    this.handleSpringUpdate = this.handleSpringUpdate.bind(this);
    this.backTimmer = null;
  }

  componentDidMount() {
    this.springSystem = new SpringSystem();
    this.spring = this.springSystem.createSpring();
    this.spring.addListener({ onSpringUpdate: this.handleSpringUpdate });
  }

  componentWillUnmount() {
    this.springSystem.deregisterSpring(this.spring);
    this.springSystem.removeAllListeners();
    this.springSystem = undefined;
    this.spring.destroy();
    this.spring = undefined;
    clearTimeout(this.backTimmer);
  }


  handleClick(e) {
    const scrollbars = article.contentScrollbars || this.props.scroll.ref;
    const scrollTop = scrollbars.getScrollTop();
    const scrollHeight = scrollbars.getScrollHeight();
    const val = MathUtil.mapValueInRange(0, 0, scrollHeight, 0, 0);
    const smoothTimmer = null;
    if (scrollTop) {
      this.spring.setCurrentValue(scrollTop).setAtRest();
      this.spring.setEndValue(val);
    }
    else {
      const event = new CustomEvent('scrolltop');
      window.dispatchEvent(event);
    }

    if (article.contentScrollbars) {
      article.contentScrollbars.scrollToTop();
    }
    if (article.smooth) {
      clearTimeout(this.backTimmer);

      this.backTimmer = setTimeout(() => {
        article.smooth.scrollTo(0);
      }, 100)
    }
  }

  handleSpringUpdate(spring) {
    const scrollbars = article.contentScrollbars || this.props.scroll.ref;
    const val = spring.getCurrentValue();
    scrollbars.scrollTop(val);
  }

  render() {
    const { classes, scroll, spState } = this.props;

    return (
      <div>
        {scroll && scroll.ref && !spState
            && (
              <div className={classNames(classes.wrapper, 'btn-back-to-top p-8 bg-teal absolute text-center text-white')}>
                <IconButton
                  className={classNames(classes.button, 'text-white')}
                  aria-label="Back To Top"
                  onClick={this.handleClick.bind(this)}
                >
                  <Up color="white" />
                </IconButton>
              </div>
            )
        }
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}

function mapStateToProps({ scroll, searchPanel }) {
  return {
    scroll,
    spState: searchPanel.state,
  };
}


export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(BackToTop));
