import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

const styles = theme => ({
  wrapper: {
    padding: '.3rem .6rem .0rem .6rem',
  },
  parent: {
    display: 'table-cell',
  },
});

function Tag(props) {
  const { classes, className, dataTrack } = props;
  return (
    <span className={classNames(classes.wrapper, 'text-16 bg-teal text-white hidden font-sans', className)} data-track={dataTrack}>
      {props.text}
      {' '}
    </span>
  );
}
export default withStyles(styles, { withTheme: true })(Tag);
