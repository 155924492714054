import * as Actions from '../actions';

const initialState = {
  isLoaded: false,
};

const addthis = function (state = initialState, action) {
  switch (action.type) {
    case Actions.SET_ADDTHIS_LOADED:
      return {
        ...state,
        isLoaded: action.isLoaded,
      };
    default:
      return state;
  }
};

export default addthis;
