import React from 'react';
import _ from 'lodash';
import BreakpointsConfig from 'configs/BreakpointsConfig';
import queryString from 'query-string';
const isAbsoluteUrl = require('is-absolute-url');

class Utils {
  static filterArrayByString(mainArr, searchText) {
    if (searchText === '') {
      return mainArr;
    }

    searchText = searchText.toLowerCase();

    return mainArr.filter(itemObj => this.searchInObj(itemObj, searchText));
  }


  static searchInString(value, searchText) {
    return value.toLowerCase().includes(searchText);
  }

  static generateGUID() {
    function S4() {
      return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
    }

    return S4() + S4();
  }

  static toggleInArray(item, array) {
    if (array.indexOf(item) === -1) {
      array.push(item);
    } else {
      array.splice(array.indexOf(item), 1);
    }
  }

  static handleize(text) {
    return text.toString().toLowerCase()
      .replace(/\s+/g, '-') // Replace spaces with -
      .replace(/\W+/g, '') // Remove all non-word chars
      .replace(/--+/g, '-') // Replace multiple - with single -
      .replace(/^-+/, '') // Trim - from start of text
      .replace(/-+$/, ''); // Trim - from end of text
  }

  static setRoutes(config) {
    let routes = [...config.routes];

    if (config.settings || config.auth) {
      routes = routes.map((route) => {
        let auth = config.auth ? [...config.auth] : [];
        auth = route.auth ? [...auth, ...route.auth] : auth;
        return {
          ...route,
          settings: { ...config.settings, ...route.settings },
          auth,
        };
      });
    }

    return [...routes];
  }

  static generateRoutesFromConfigs(configs) {
    let allRoutes = [];
    configs.forEach((config) => {
      allRoutes = [
        ...allRoutes,
        ...this.setRoutes(config),
      ];
    });
    return allRoutes;
  }

  static findById(o, id) {
    // Early return
    if (o.id === id) {
      return o;
    }
    let result; let
      p;
    for (p in o) {
      if (o.hasOwnProperty(p) && typeof o[p] === 'object') {
        result = this.findById(o[p], id);
        if (result) {
          return result;
        }
      }
    }
    return result;
  }

  static difference(object, base) {
    function changes(object, base) {
      return _.transform(object, (result, value, key) => {
        if (!_.isEqual(value, base[key])) {
          result[key] = (_.isObject(value) && _.isObject(base[key])) ? changes(value, base[key]) : value;
        }
      });
    }

    return changes(object, base);
  }

  static getDefaultLocations() {
    return [
      { id: 'cbd', name: 'CBD' },
      { id: 'central', name: 'Central' },
      { id: 'east', name: 'East' },
      { id: 'north', name: 'North' },
      { id: 'south', name: 'South' },
      { id: 'west', name: 'West' },
    ];
  }

  static getDefaultSorts() {
    return [
      { id: 'date_dsc', name: 'Newest First' },
      { id: 'date_asc', name: 'Oldest First' },
      { id: 'alpha_asc', name: 'Alphabetical (A-Z)' },
      { id: 'alpha_dsc', name: 'Alphabetical (Z-A)' },
    ];
  }

  static resolveImageUrl(url) {
    if (typeof url === 'string') {
      if (isAbsoluteUrl(url)) {
        return encodeURI(url);
      } if (this.searchInString(url, 'storage/')) {
        return process.env.REACT_APP_IMGSRCE_URL + encodeURI(url);
      }
      // return `${process.env.REACT_APP_IMGSRCE_URL}/storage/${encodeURI(url)}`;
      return `${process.env.REACT_APP_IMGSRCE_URL}${encodeURI(url)}`;
    }
  }

  static isMobile() {
    return window.innerWidth < BreakpointsConfig.md;
  }

  static isDevice() {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  }

  static isLargeScreen() {
    return window.innerWidth >= BreakpointsConfig.lg;
  }
  static getQueryStringValue (key) {
    return decodeURIComponent(window.location.search.replace(new RegExp("^(?:.*[&\\?]" + encodeURIComponent(key).replace(/[\.\+\*]/g, "\\$&") + "(?:\\=([^&]*))?)?.*$", "i"), "$1"));
  }

  static highlightText(text, highlight) {
    const pattern = new RegExp(highlight, 'gi');
    const matches = text.match(pattern);
    const unmatches = text.split(pattern);
    if (matches && matches.length > 0) {
      const elements = unmatches.reduce((acc, unmatchItem, idx) => {
        if (idx === 0) {
          return acc.concat([unmatchItem]);
        }
        return acc.concat([<strong>{matches[idx - 1]}</strong>, unmatchItem]);
      }, []);
      return (
        <React.Fragment>
          {elements}
        </React.Fragment>
      );
    }
    return text;
  }

  static formatAPIparams(search) {
    const params = queryString.parse(search) || {};
    const formattedParams = _.map(params, (param, key) => {
      if (_.isArray(param)) {
        return `${key}=${param.join(',')}`;
      }
      return `${key}=${param}`;
    });
    return queryString.parse(formattedParams.join('&'));
  }

  static isIOS() {
    return /iPhone|iPad/i.test(navigator.userAgent);
  }


  static triggerPageView() {

    let prevPage = localStorage.getItem("prevPage");
    let currentPage = window.location.href;

    if (prevPage != null) {

      if (prevPage != currentPage) {
        // console.log('utils - triggerPageView', currentPage);
        window.dataLayer.push({
            'event': 'Pageview',
            'url': window.location.href,
        })
      }
    }
    localStorage.setItem("prevPage", currentPage);
  }

  static removeTagsAndSymbols(text) {
    return text.replace(/(<([^>]+)>|&([^>]+);)/gi, "");
  }

}

export default Utils;
