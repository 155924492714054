import React, { Component } from 'react';
import Loadable from 'react-loadable';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import Loading from 'components/Elements/Loading';
import HeaderText from 'components/Elements/HeaderText';
import _ from 'lodash';
import api from 'configs/ApiConfig';
import utils from 'utils';
import DealsFilters from './filters';

const queryString = require('query-string');
const styles = theme => ({
  root: {},
});

class Deals extends Component {
  state = {
    filter: {
      size: 12,
    },
    filterLoaded: false,
  };

  componentWillMount() {
    this.props.filter && this.setState({ filter: this.props.filter });
  }

  handleFilterLoaded = () => {
    if (!this.state.filterLoaded) {
      this.setState({
        filterLoaded: true,
      });
    }
  }

  render() {
    utils.triggerPageView();
    const {
      classes,
      showFilters,
      title,
      subtitle,
      location: { pathname, search },
    } = this.props;

    const { filterLoaded } = this.state;
    const filter = utils.formatAPIparams(search);
    const LoadableComponent = Loadable.Map({
      loader: {
        data: () => api.get('/deals', { params: { ...filter, size: 12 }})
          .then(res => res.data),
        cards: () => import('components/Layouts/CardDeals'),
      },
      delay: 500,
      timeout: 10000,
      loading: props => <Loading {...props} />,
      render: (loaded, props) => {
        document.title = "Exclusive Food Deals & Restaurant Promotions in Singapore - The Dining Advisor";
        const Cards = loaded.cards.default;

        return (
          <Cards
            {...props}
            total={loaded.data.totalData}
            data={loaded.data.data}
            circular={false}
            variant="contained"
            loadMore={{ api: 'deals', params: { ...filter, size: 12 }}}
            page="deals"
            type="deals"
            category=""
            origin="deals home"
          />
        );
      },
    });

    return (
      <div className={classNames(classes.root, 'container mb-48 md:px-20')}>

        <HeaderText
          title={title || 'Deals'}
          subtitle={subtitle
              || 'Irresistible dining deals and promotions for every craving.'}
          subtitleCollapse={"<br><br>From the finest Japanese cuisine with Gourmet Japan to amazing 1-for-1 deals and festive discounts, your UOB Card is the key to Singapore's finest food deals. Whether it's a casual catch up at cafes or a fine dining experience with loved ones, The Dining Advisor is here to help you get the best dining promotions and festive deals as a UOB Cardmember. Start browsing awesome island-wide deals today. "}
        />

        <DealsFilters
          // onChange={this.filterContent}
          onLoad={this.handleFilterLoaded.bind(this)}
        />

        {(filterLoaded || !showFilters)
          && (
            <LoadableComponent
              {...this.props}
            />
          )
        }

      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(withRouter(Deals));
