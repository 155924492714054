import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import matchSorter from 'match-sorter';
import _ from 'lodash';
import ListMenu from './Elements/listmenu';
import GroupMenu from './Elements/groupmenu';
import AvatarMenu from './Elements/avatarmenu';
import Arrow from './Elements/arrow';
import InputField from './Elements/inputField';
import Label from './Elements/label';
import MultiSelect from './Elements/multiselect';
import BreakpointsConfig from 'configs/BreakpointsConfig';
import utils from 'utils';

const styles = theme => ({
  selectInner: {
    [theme.breakpoints.down(BreakpointsConfig.md)]: {
      '&[aria-expanded="true"]': {
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: 2000,
        '& .list-option': {
          height: 'calc(100vh - 51px)',
          paddingBottom: 70,
          overflow: 'scroll',
        },
      },
    },
  },
  defaultSelect: {
    appearance: 'none',
  },
});

class SelectFilter extends Component {
  input = React.createRef()
  itemToString = item => (item ? item.name : '')

  getParamArr(params, key) {
    return _.isArray(params[key]) ? params[key] : [params[key] || ''];
  }

  handleClearSelection = () => {
    const { filterKey, history, location: { pathname, search }} = this.props;
    const params = queryString.parse(search) || {};
    history.push({
      pathname,
      search: `?${queryString.stringify({ ...params, [filterKey]: '' })}`,
    });
  }

  handleSelect = (selectedItem) => {
    const { primaryKey, singleSelect, filterKey, history, location: { pathname, search }} = this.props;

    const params = queryString.parse(search) || {};
    if (selectedItem.id === '') {
      this.handleClearSelection();
    } else {
      if (singleSelect) {
        history.push({
          pathname,
          search: `?${queryString.stringify({
            ...params,
            [filterKey]: selectedItem[primaryKey],
          })}`,
        });
      } else {
        const curSelectedItems = this.getParamArr(params, filterKey).filter((item) => item !== '');
        const isExist = curSelectedItems.some((item) => item === selectedItem[primaryKey]);
        const newSelectedItems = isExist ?
        curSelectedItems.filter((item) => item !== selectedItem[primaryKey]) :
        curSelectedItems.concat([selectedItem[primaryKey]]);
        history.push({
          pathname,
          search: `?${queryString.stringify({
            ...params,
            [filterKey]: newSelectedItems,
          })}`,
        });
      }
    }
  }

  getSelectedItems = (items) => {
    const { primaryKey, filterKey, location: { search }} = this.props;
    const params = queryString.parse(search) || {};
    const selectedItems = this.getParamArr(params, filterKey);
    return items.filter((item) =>
    selectedItems.some((selectedItem) => item[primaryKey] === selectedItem));
  }

  render() {
    const {
      label, type, avatar = false, listgroup = false, items = [], singleSelect, defaultValue, allText,
      isSelectMap, classNameLabel, filterKey, classes, useDefaultSelect, onChange, fullmenu=false,
    } = this.props;
    const getItems = (filter) => {
      const _items = items;

      if (isSelectMap) {
        _items.map((item, idx) => {
          item.id = idx;
          return item;
        });
      }

      if (!_.find(items, { id: '', slug: '', name: (allText || 'All') }) && !singleSelect && !avatar) {
        _items.unshift({
          id: '',
          slug: '',
          name: (allText || 'All'),
        });
      }

      return filter
        ? matchSorter(_items, filter, {
          keys: ['name'],
        })
        : _items;
    };
    const selectedItems = this.getSelectedItems(getItems());

    const shouldUseDefaultSelect = utils.isMobile() && useDefaultSelect;

    return (
      <div className="flex flex-col">

        <MultiSelect
          onChange={this.handleSelect}
          itemToString={this.itemToString}
          singleSelect={singleSelect}
          defaultValue={selectedItems}
          items={getItems()}
          filterKey={filterKey}
        >
          {({
            getInputProps,
            getToggleButtonProps,
            getMenuProps,
            removeItem,
            isOpen,
            inputValue,
            selectedItems,
            getItemProps,
            highlightedIndex,
            toggleMenu,
          }) => {
            const styleWrapper = {
              'relative px-28 pr-44 py-16 md:px-20 md:py-12 cursor-pointer': true,
              'bg-black': isOpen,
              'bg-white': !isOpen,
            };
            return (
              <div className={classNames('w-full', fullmenu ? 'static' : 'relative', classes.selectInner)}>
                {
                  shouldUseDefaultSelect ?
                  <div>
                    <select
                      onChange={(e) => {
                        const selectedItem = _.find(items, { id: e.target.value });
                        this.handleSelect(selectedItem);
                      }}
                      className={classNames(
                        styleWrapper,
                        classes.defaultSelect,
                        'w-full text-black lg:text-14 md:text-14 sm:text-12 uppercase font-bold md:font-normal fontAgendaBold',
                      )}
                    >
                      {
                        getItems(inputValue).map((item, index) => (
                          <option key={item.id} value={item.id}>{item.name}</option>
                        ))
                      }
                    </select>
                    <Arrow isOpen={isOpen} />
                  </div> :
                  <div
                    className={classNames(styleWrapper)}
                    onClick={() => {
                      toggleMenu();
                      !isOpen && this.input.current.focus();
                    }}
                  >
                    <Label label={label} isOpen={isOpen} classNameLabel={classNameLabel}/>
                    <InputField
                      input={this.input}
                      selectedItems={selectedItems}
                      getInputProps={getInputProps}
                      removeItem={removeItem}
                      isOpen={isOpen}
                      inputValue={inputValue}
                    />
                    <Arrow isOpen={isOpen} />
                  </div>
                }

                {isOpen && avatar
                && (
                  <AvatarMenu
                    getMenuProps={getMenuProps}
                    getItemProps={getItemProps}
                    isOpen={isOpen}
                    inputValue={inputValue}
                    items={items}
                    getItems={getItems}
                    selectedItems={selectedItems}
                    highlightedIndex={highlightedIndex}
                    handleClearSelection={this.handleClearSelection}
                    singleSelect={singleSelect}
                    defaultValue={defaultValue}
                    type={type}
                  />
                )
                }
                
                 

                {isOpen && !avatar && !shouldUseDefaultSelect && !listgroup
                && (
                  <ListMenu
                    getMenuProps={getMenuProps}
                    getItemProps={getItemProps}
                    isOpen={isOpen}
                    inputValue={inputValue}
                    items={items}
                    getItems={getItems}
                    selectedItems={selectedItems}
                    highlightedIndex={highlightedIndex}
                    singleSelect={singleSelect}
                    defaultValue={defaultValue}
                  />
                )
                }
                {isOpen && !avatar && !shouldUseDefaultSelect && listgroup
                && (
                  <GroupMenu
                    getMenuProps={getMenuProps}
                    getItemProps={getItemProps}
                    isOpen={isOpen}
                    inputValue={inputValue}
                    items={items}
                    getItems={getItems}
                    selectedItems={selectedItems}
                    highlightedIndex={highlightedIndex}
                    handleClearSelection={this.handleClearSelection}
                    singleSelect={singleSelect}
                    defaultValue={defaultValue}
                  />
                )
                }

            </div>
          )}}
        </MultiSelect>
      </div>
    );
  }
}

SelectFilter.defaultProps = {
  primaryKey: 'slug',
};

export default withStyles(styles, { withTheme: true })(withRouter(SelectFilter));
