import React, { Component } from 'react';
import classNames from 'classnames';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';

class ArticleAvatar extends Component {
  render() {
    const {
      className, alt, src, label, line,
    } = this.props;

    return (
      <div className={classNames(className)}>
        {line
        && <hr className="w-36 border-t-2 border-white md:border-grey-dark mx-0 my-12 mr-auto" />
        }
        <div className="flex flex-row">
          {src
          && <Avatar alt={alt} src={src} />
          }
          {label
          && (
            <Typography className="self-center p-8 text-12 text-white md:text-black">
              {label}
            </Typography>
          )
          }
        </div>
      </div>
    );
  }
}

export default (ArticleAvatar);
