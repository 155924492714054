import React, { Component } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { withRouter } from 'react-router-dom';
import CardContent from '@material-ui/core/CardContent';
import PropTypes from 'prop-types';
import { withStyles, Grid, Typography } from '@material-ui/core';
import { Link } from 'react-router-external';
import utils from 'utils';
import _ from 'lodash';
import Tag from 'components/Elements/Tag';
import BreakpointsConfig from 'configs/BreakpointsConfig';

const styles = theme => ({
  root: {},
  title: {
    'padding-bottom': '0 !important',
  },
  description: {
    '& ul': {
      'padding-inline-start': '20px',
    },
  },
  widthHalf: {
    width: '50%',
  },
  circular: {
    borderRadius: '50%',
  },
  contained: {
  },
  center: {
    'margin-left': '50%',
    transform: 'translateX(-50%)',
  },
  borderTags: {
    '& a': {
      marginLeft: '.2rem',
      marginRight: '.2rem',
      padding: '.2rem',
      borderLeft: '1px solid black',
      borderSpacing: '.2rem'
    }
  },
  height275: {
    height: 275,
    [theme.breakpoints.down(BreakpointsConfig.md)]: {
      height: 240,
    },
  },
});

const typeLinkMapper = {
  ugs: 'gourmet-stories',
  guides: 'foodie-guides',
  deals: 'deals',
  reviews: 'reviews',
}

const getSubLink = (type, { slug = '' }) => `/${typeLinkMapper[type]}/${slug}`;
const getReviewSubLink = (type, advisor,{ slug = '' }) => `/${typeLinkMapper[type]}/${advisor}/${slug}`;


class CardResult extends Component {
  componentDidMount() {
    if (window.addthis) {
      window.addthis.toolbox('.addthis_toolbox');
    }
  }

  getSubDescription = content => {
    const { review, guide, deal } = content;
    const { keyword } = this.props;

    if(guide) {
      console.log(content);
      if (content.type == "gourmet-stories") {
        return {
            type: 'guides',
            title: "VIEW STORY",
            description: _.get(guide, ['description']) || '',
            slug: _.get(guide, ['slug']) || '',
            link: getSubLink('ugs', guide),
          }
      } else {
        return {
          type: 'guides',
          title: "VIEW STORY",
          description: _.get(guide, ['description']) || '',
          slug: _.get(guide, ['slug']) || '',
          link: getSubLink('guides', guide),
        }
      }
    } else {
      let subs = [];
      if (review) {
        subs.push({
          type: 'reviews',
          title: 'READ REVIEW',
          slug: _.get(review, ['slug']),
          advisorName: _.get(review, ['advisor', 'name']) || '',
          advisorSlug:  _.get(review, ['advisor', 'slug']) || '',
          advisorType: _.get(review, ['advisor', 'type']) || 'advisors',
          link: getReviewSubLink('reviews',(_.get(review, ['advisor', 'slug']) || ''), review),
        });
      }
      if (deal) {
        subs.push({
          type: 'deals',
          title: 'VIEW DEAL',
          slug: _.get(deal, ['slug'], ''),
          description:  _.get(deal, ['description']) || '',
          link: getSubLink('deals', deal),
        })
      }
      return subs;
    }
  }

  render() {
    const { classes, content, variant, keyword, tag: fixedTag, dataTrack, } = this.props;
    const title = content.title || content.name;
    const image = content.image || content.img || content.picture;
    const imageURL = utils.resolveImageUrl(
      content.image || content.img || content.picture,
    );
    const tagsFeatured = _.get(content, ['tags', 'featured']);
    const subs = this.getSubDescription(content);
    const tags = fixedTag ? [ fixedTag ] : _.get(content, ['tags', 'data']);
    const info = content.info;
    let variantClass;
    const bgInlineStyle = {
      background: `url("${decodeURI(imageURL)}")`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundPosition: 'center center',
      transition: 'all .3s ease-in-out',
    };
    switch (variant) {
      case 'contained':
        variantClass = `${classes.contained}`;
        bgInlineStyle.width = '100%';
        break;
      case 'transparent':
        break;
      default:
        variantClass = ``;
        break;
    }
    return (
      <Grid
        justify="space-between"
        className={`overflow-hidden h-full border-t-2 border-grey-light items-stretch m-8 mt-0 pt-2 md:min-h-96 ${variantClass} search-card`}
      >
        <div className="self-start w-full ">
          <Grid container alignContent="space-between w-full ">
            <Grid item xs={12}>
              <CardContent
                className={`${classes.title} px-0 md:min-h-160 search_card-content`}
                justify="space-around"
              >
                <div className={`flex w-full flex-wrap h-50 search-tags-wrapper ${classes.borderTags}`} data-track={dataTrack}>
                  {tagsFeatured &&
                    <Tag text="FEATURED" customStyle = {true} className="search-tag" dataTrack={dataTrack}/>
                  }
                  { tags.length > 0 && _.map(tags, tag => (
                    <div
                      className={`no-underline font-sans pt-4 search-tag-link `}
                    >
                      <Typography className="text-16 text-teal font-sans text-left font-hairline no-underline uppercase ">
                        <span className="ml-4 font-100" data-track={dataTrack}> {tag.name} </span>
                      </Typography>
                    </div>
                    ))
                  }
                </div>
                {title && (
                  <Typography
                    component={'h5'}
                    variant="inherit"
                    className="text-20 text-black no-underline font-serif text-left search-title"
                  >
                    <span data-track={dataTrack}>  { _.truncate(title, {length:44})} </span>
                  </Typography>
                )}
                <div className="flex w-full flex-wrap category-name text-14  pb-8 pt-0">
                { info.length > 0 &&  
                  <Typography className="cursor-pointer font-sans text-left no-underline">
                    <span className="text-grey-darker mr-4 cursor-pointer " data-track={dataTrack}>{_.map(info, ({ name }) => name).join(' | ')}</span>
                  </Typography>
                
                }
                </div>
              </CardContent>
            </Grid>
          </Grid>
        </div>
        <div className="w-full">
          {image && (
              <div
                className={classNames(
                  classes.center,
                  'image w-224 w-full',
                  classes.height275
                )}
                style={bgInlineStyle}
                data-track={dataTrack}
              />
          )}
        </div>
        <div className="container flex search-caption">
          { subs.type === 'guides' ? (
            <div className="container w-full">
              <Link
                className = "no-underline"
                to={subs.link}
                   data-track={`Recommender search >> ${subs.type} >> ${title} `}
              >
                <Typography
                  variant="inherit"
                  className="uppercase text-teal text-base leading-none font-sans no-underline search-title-card"
                >
                  <span data-track={`Recommender search >> ${subs.type} >> ${title} `}>
                    {subs.title}
                  </span>
                </Typography>
              </Link>
              <Typography className="text-15 font-sans pt-8">
                <span data-track={dataTrack} dangerouslySetInnerHTML={{ __html: subs.description }} />
              </Typography>
            </div>
          ) : _.map(subs, sub => (
            <div className={classNames("caption-50", subs.length === 2 ? classes.widthHalf : 'w-full')}>
                <Link
                  className = "no-underline"
                  to={sub.link}
                >
                  <Typography
                    variant="inherit"
                    className="uppercase text-teal cursor-pointer text-base leading-none font-sans no-underline search-title-card"
                  >
                    <span data-track={`Recommender search >> ${sub.type} >> ${title} `}>
                      {sub.title}
                    </span>
                  </Typography>
                </Link>
              {sub.type === 'reviews' ? (
                <Typography
                  component = {Link}
                  className="text-15 leading-tight cursor-pointer text-teal font-sans py-0 no-underline"
                  to={`/${sub.advisorType}/${sub.advisorSlug}`}
                >
                  <span data-track={dataTrack}>
                    <span className="text-black "> by </span> {sub.advisorName}
                  </span>
                </Typography>
              ) : (
                <Typography className="text-15 leading-tight text-black font-sans pt-4">
                  <span data-track={dataTrack} dangerouslySetInnerHTML={{ __html: sub.description }} />
                </Typography>
              )
            }
            </div>
            ))
          }
        </div>
      </Grid>
    );
  }
}

CardResult.propTypes = {
  classes: PropTypes.object,
  type: PropTypes.string,
  hideSocial: PropTypes.bool,
  content: PropTypes.object,
  variant: PropTypes.string,
  children: PropTypes.array,
  origin: PropTypes.string,
};

function mapStateToProps({ addthis }) {
  return {
    addthis,
  };
}
export default withStyles(styles, { withTheme: true })(
  withRouter(connect(mapStateToProps)(CardResult)),
);
