import Reviews from './index';
import { ReviewDetails } from './detail';
import React from "react";
import { Redirect } from 'react-router';

export const ReviewsConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: '/reviews/all-advisors',
      component: (props) => <Redirect to={"/reviews"} />
    },
    {
      path: '/reviews/:advisor/:slug',
      component: ReviewDetails,
    },
    {
      path: '/reviews/:slug',
      component: ReviewDetails,
    },
    {
      path: '/reviews',
      component: Reviews,
    },
  ],
};
