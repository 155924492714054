import React from 'react';
import { withStyles } from '@material-ui/core/styles/index';
import classNames from 'classnames';
import {
  Typography, Hidden, CardMedia, CardContent,
} from '@material-ui/core';
import { Link } from 'react-router-external';
import utils from 'utils';
import BreakpointsConfig from 'configs/BreakpointsConfig';

const styles = theme => ({
  root: {},
  textContent: {
    [theme.breakpoints.up(BreakpointsConfig.md)]: {
      position: 'absolute',
      background: 'linear-gradient(rgba(0,0,0,0), rgba(0,0,0,1))',
      '& .textItem': {
        color: '#FFFFFF',
      },
    },
    [theme.breakpoints.down(BreakpointsConfig.md)]: {
      '& .textItem': {
        color: '#000000',
      },
    },
  },
  text30: {
    fontSize: 30,
  },
  cardHeroImg: {
    maxHeight: 450,
  }
});

function CardHero({
  classes, className, content, handleClick, type, origin, category, custom,
}) {
  const title = content.title_big || content.title || '';
  const subtitle = content.subtitle || '';
  const categoryName = custom || (content.category && content.category.name) || (content.foodie_category && content.foodie_category.name);
  const image = content.image_mobile || content.image;
  const imageUrl = image && (utils.resolveImageUrl(image));
  const cardUrl = (content.slug != 'gourmet-stories') ? type+'/'+content.slug : '/gourmet-stories';

  return (
    <div className={classNames(classes.root, 'w-full h-full sm:min-h-256 ', className)}>
      <Hidden mdUp>
        <Link to={cardUrl}>
          <CardMedia
            className={classNames(classes.media, 'lg:min-h-512 sm:min-h-256 w-full cursor-pointer')}
            image={imageUrl}
          >
            <div className={classNames(classes.media, 'lg:min-h-512 sm:min-h-256 w-full cursor-pointer')} data-track={`${origin} >> ${categoryName && (`${categoryName} >> `)}${title}`} />
          </CardMedia>
        </Link>
        <CardContent className="p-0 pr-20 pl-20">
          <div className={classNames(classes.textContent, 'w-full')}>
            {category
              && (
                <Typography component="h5" variant="inherit" className="sm:mt-20 py-0 text-16 uppercase text-teal leading-none font-sans">
                  <span data-track={`${origin} >> ${categoryName && (`${categoryName} >> `)}${title}`}>
                    {category}
                  </span>
                </Typography>
              )
            }
            {title
              && (
                <Link to={cardUrl} className="no-underline" data-track={`${origin} >> ${categoryName && (`${categoryName} >> `)}${title}`}>
                  <Typography component="h3" variant="inherit" className={classNames("no-underline cursor-pointer my-16 textItem lg:mr-52 leading-none font-serif", classes.text30)}>
                    <span data-track={`${origin} >> ${categoryName && (`${categoryName} >> `)}${title}`}>
                      {title}
                    </span>
                  </Typography>
                </Link>
              )
            }
            {subtitle
              && (
                <Typography component="p" variant="inherit" className="textItem lg:mr-52 leading-none text-16 font-sans">
                  <span data-track={`${origin} >> ${categoryName && (`${categoryName} >> `)}${title}`}>
                    {subtitle}
                  </span>
                </Typography>
              )
            }
          </div>
        </CardContent>
      </Hidden>

      <Hidden smDown>
        <Link to={cardUrl}
        className={classNames("block w-full h-full", classes.cardHeroImg)}>
          <CardMedia
            className={classNames(classes.media, 'relative flex-1 min-h-256 w-full h-full cursor-pointer')}
            image={imageUrl}
          >
            <div className={classNames(classes.media, 'relative flex-1 min-h-256 w-full h-full cursor-pointer')} data-track={`${origin} >> ${categoryName && (`${categoryName} >> `)}${title}`}/>
            <div className={classNames(classes.textContent, 'absolute pin-b p-12 pt-20 w-full')}>
              {category
                && (
                  <Typography component="h4" variant="inherit" className="p-8 uppercase text-teal leading-none text-16 font-sans fontAgendaMedium">
                    <span data-track={`${origin} >> ${categoryName && (`${categoryName} >> `)}${title}`}>
                      {categoryName}
                    </span>
                  </Typography>
                )
              }
              {title
                && (
                  <Link to={cardUrl} className="no-underline">
                    <Typography component="h3" variant="inherit" className="no-underline cursor-pointer p-8 text-32 textItem lg:mr-52 leading-none font-serif">
                      <span data-track={`${origin} >> ${categoryName && (`${categoryName} >> `)}${title}`}>
                        {title}
                      </span>
                    </Typography>
                  </Link>
                )
              }
              {subtitle
                && (
                  <Typography component="p" variant="inherit" className="p-8 textItem lg:mr-52 leading-none text-16 font-sans fontAgendaMedium">
                    <span data-track={`${origin} >> ${categoryName && (`${categoryName} >> `)}${title}`}>
                      {subtitle}
                    </span>
                  </Typography>
                )
              }
            </div>
          </CardMedia>
        </Link>
      </Hidden>
    </div>
  );
}

export default withStyles(styles, { withTheme: true })(CardHero);
