import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-external';
import BreakpointsConfig from 'configs/BreakpointsConfig';

const styles = theme => ({
    root: {
        '& a:not(.color-666666)': {
            textDecoration: 'none !important',
            color: '#1f1e18',
        },
    },
    contentDeal: {
        lineHeight: 'initial',
        '&>span': {
            fontFamily: 'Playfair-Black',
            fontSize: '26px',
            paddingBottom: '5px',
            display: 'block',
            lineHeight: 1.2,
            '& li': {
                listStyle: 'none',
                paddingTop: '20px',
            }
        },
        '& ul': {
            fontFamily: 'Playfair-Black',
            fontSize: '26px',
            paddingBottom: '5px',
            display: 'block',
            lineHeight: 1.2,
            '& li': {
                listStyle: 'none',
                paddingTop: '20px',
            }
        },
        '& .cta': {
            paddingTop: '15px',
            fontFamily: 'Agenda-Medium',
            paddingTop: '1rem',
            fontSize: 15,
            color: '#666',
            textDecoration: 'underline'
        },
        '& .tnc': {
            paddingTop: '15px',
            fontFamily: 'Agenda-Medium',
            paddingTop: '1rem',
            fontSize: 12,
            color: '#666',
        },
        // '&p': {
        //   fontFamily: 'Playfair-Black',
        //   fontSize: '26px',
        //   paddingBottom: '5px',
        //   display: 'block',
        //   lineHeight: 1,
        //   '&:last-child': {
        //     paddingBottom: '10px',
        //   },
        //   [theme.breakpoints.up(BreakpointsConfig.md)]: {
        //     fontSize: '34px',
        //     '&:last-child': {
        //       paddingBottom: '15px',
        //     },
        //   }
        // },
        // '& p:last-child:nth-child(n+2)': {
        //   paddingTop: '1rem',
        //   fontSize: 12,
        //   fontFamily: 'fontAgendaMedium',
        //   color: '#666',
        // },
        // '& p > a': {
        //   fontFamily: 'Playfair-Black',
        //   fontSize: '26px',
        //   paddingBottom: '5px',
        //   display: 'block',
        //   lineHeight: 1,
        //   '&:last-child': {
        //     paddingBottom: '10px',
        //   },
        //   [theme.breakpoints.up(BreakpointsConfig.md)]: {
        //     fontSize: '34px',
        //     '&:last-child': {
        //       paddingBottom: '15px',
        //     },
        //   }
        // },
    }
});

class ArticleDeal extends Component {

    renderTitle(dealMerchants) {
        const merchantDetailsWithName = dealMerchants.map((merchant) =>
            <React.Fragment>
                {this.props.article.show_deal_title === 1 &&
                    <span>
                        {merchant.deal_merchant_name}
                    </span>
                }
                <span dangerouslySetInnerHTML={{ __html: merchant.deal_full_description }} />
                <div class="cta">
                    <a href={"/deals/" + merchant.slug} target="_blank">Find out more</a>
                </div>
                <p class="tnc">{merchant.deal_tnc}</p>
                <br />
            </React.Fragment>
        );

        return merchantDetailsWithName;
    }

    render() {

        const {
            article, classes, className, title, content, tnc,
        } = this.props;

        let new_deals = false;
        if (article != undefined) {
            new_deals = true;
        }

        return (
            <div className={classNames(classes.root)}>
                {content
                    && (
                        <div
                            className={classNames(
                                'lg:mx-96 sm:mx-20 my-20 text-center p-16 md:p-20',
                                'border-3 border-solid border-blue-darker',
                                className
                            )}
                        >
                            <img src="/assets/images/elements/cards.png" alt="cards" className="w-160" />
                            {title
                                && (
                                    <Typography gutterBottom component="h4" variant="inherit" className="fontPlayfairDisplayBlack text-16 color-1f1e18">
                                        <span dangerouslySetInnerHTML={{ __html: title }} className="padding-5" />
                                        <hr className="mx-auto my-0 w-36 border-t-3 border-teal" />
                                    </Typography>
                                )
                            }

                            { !new_deals && content
                                && (
                                    <Typography gutterBottom className={classNames("color-1f1e18 text-11", classes.contentDeal)}>
                                        <span dangerouslySetInnerHTML={{ __html: content }} />
                                    </Typography>
                                )
                            }

                            {/* <React.Fragment>
                                {this.renderTitle(article.new_deals)}
                            </React.Fragment>
                            */}

                            { new_deals && content
                                && (
                                    <Typography gutterBottom className={classNames("color-1f1e18 text-11", classes.contentDeal)}>
                                        {this.renderTitle(article.new_deals)}
                                    </Typography>
                                )
                            }

                            {tnc
                                && (
                                    <Typography
                                        gutterBottom
                                        component={Link}
                                        to={tnc}
                                        target="_blank"
                                        className="text-10 md:text-17 mt-12 fontAgendaMedium color-666666 no-underline"
                                    >
                                        Terms and conditions apply
                                    </Typography>
                                )
                            }
                        </div>
                    )
                }
            </div>
        );
    }
}

export default withStyles(styles, { withTheme: true })(ArticleDeal);
