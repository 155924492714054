import React, { Component } from 'react';
import classNames from 'classnames';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUp from '@material-ui/icons/KeyboardArrowUp';
import BreakpointsConfig from 'configs/BreakpointsConfig';
import { withStyles } from '@material-ui/core';

const styles = theme => ({
  btnArrow: {
    right: 38,
    bottom: 10,
    [theme.breakpoints.down(BreakpointsConfig.md)]: {
      right: 20,
      bottom: 'auto',
      top: '50%',
      transform: 'translateY(-50%)',
    }
  },
  arrowIcon: {
    width: 40,
    height: 30,
  }
});

class SelectMenuArrow extends Component {
  render() {
    const { isOpen, classes } = this.props;

    return (
      <span
        className={classNames('absolute w-32',
          'flex flex-col justify-center', classes.btnArrow)}
      >
        {isOpen
          ? <KeyboardArrowUp  className={classNames('text-white', classes.arrowIcon)} />
          : <KeyboardArrowDown className={classNames('text-grey-darkest', classes.arrowIcon)} />
        }
      </span>
    );
  }
}
export default withStyles(styles, { withTheme: true })(SelectMenuArrow);
