import React, {Component} from 'react';
import classNames from 'classnames';
import {connect} from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core';
import Loading from 'components/Elements/Loading';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Card from 'components/Cards/ResultSearchCard';
import HeaderText from 'components/Elements/HeaderText';
import API from 'configs/ApiConfig';
import { SearchFilters } from '../../pages/search/filters'
import _ from 'lodash';
import Slider from 'react-slick';
import utils from 'utils';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import('slick-carousel/slick/slick.css');
import('slick-carousel/slick/slick-theme.css');


const titleText = {
  0: 'Craving for more "$tagname" ?',
  default: 'Feeling like something for "$tagname" ?'
};

const styles = () => ({
  root: {},
  fullWidth: {
    width: '100%',
  },
  halfWidth: {
    width: '50%',
  },
  maxWidth300: {
    maxWidth: '30rem',
    paddingRight: '2rem',
  },
});
const pagerSettings = {
  dots: false,
  infinite: true,
  speed: 500,
  focusOnSelect: true,
  swipeToSlide: true,
  arrows: false,
  variableWidth: true,
};
class SearchResult extends Component {
    state = {
      isLoadingMore: false,
      _data: null,
      _page: 0,
      featuredDealData: [],
      relatedCruisineData: [],
      hasMore: true,
      isLoading: false,
      total: 0,
      size: 9,
      nextPage: 0,
      filter: {
        size: 9,
        page: 0
      },
      filterLoaded: false
    }
    componentWillMount() {
      const { loadMore } = this.props
      this.getSearchResult(loadMore.api, loadMore.params);

    }

    componentWillReceiveProps() {
      const { scroll, loadMore } = this.props
      const { hasMore, isLoadingMore } = this.state;

      if (scroll && scroll.top > 0.9 && loadMore && hasMore && !isLoadingMore) {
        this.delayedLoading();
      }
    }

    getSearchResult = (api, params) => {

      return API.get(api, {params: params})
      .then(({data}) => {
        this.setState({
          total: data.totalData,
          hasMore: data.loadMore,
          _data: data.data,
          _page: data.currentPage,
          nextPage: data.currentPage + 1,
          size: params.size,
          featuredDealData: data.featured_deals,
          relatedCruisineData: data.related_cuisines,
        })
      })
      .catch(() => {
        this.setState({
          total: 0,
          hasMore: false,
          _data: [],
          _page: 0,
          nextPage: 0,
          size: params.size,
          featuredDealData: [],
          relatedCruisineData: [],
        })
      });
    }
    loadMoreBtnClick (e) {
      this.delayedLoading();
    }
    delayedLoading() {
        const {loadMore, delay } = this.props;
        const {hasMore, _data, isLoadingMore, size, nextPage} = this.state;
        if (!isLoadingMore && hasMore) {
          this.setState({
            isLoadingMore: true
          })
        }
        if(_data) {
          setTimeout(() => {
            if (loadMore && loadMore.api && hasMore) {
              if (hasMore) {
                API.get(loadMore.api, {
                  params: {
                    ...loadMore.params,
                    size,
                    page: nextPage,
                  }
                }).then(response => {
                  let _tempData = _data
                  _.mapValues(response.data.data, (resData) => {
                    if(!_.find(_tempData, {id: resData.id})) {
                      _tempData.push(resData)
                    }
                  })
                  this.setState({
                    isLoadingMore: false,
                    _data : _tempData,
                    hasMore: response.data.loadMore,
                    total: response.data.totalData,
                    _page: response.data.currentPage,
                    nextPage: response.data.currentPage + 1
                  })

                })
              }
            }
          }, delay || 1000);
        }
    }
    filterContent = (key, selectedItems) => {
      const items = [];
      const { filter } = this.state;
      selectedItems.map(item => items.push(item.slug || item.id));

      const params = _.merge(filter, {
        [key]: items.join(),
        page: 0,
        size: 9,
      });
      this.setState({
        filter: params,
        _data: null,
        _page: 0,
        featuredDealData: [],
        relatedCruisineData: [],
      }, () => {
        const {loadMore} = this.props
        this.getSearchResult( loadMore.api, params );
    });
    }

    handleFilterLoaded = () => {
      const { filterLoaded } = this.state;
      if (!filterLoaded) {
        this.setState({
          filterLoaded: true,
        });
      }
    }


    render()
    {
      const { classes, title, subtitle, keyword, suggested, loadMore, loadMoreText, haveBorder, isSlider, className } = this.props
      const { hasMore, _data, isLoadingMore, isLoading, total, featuredDealData, relatedCruisineData } = this.state
      return (
            <div className={classNames("my-20", haveBorder && classes.className, className)}>
              {
                _data ? <HeaderText
                small
                className='font-serif text-32 text-black search-result-text mb-48'
                title={
                  !isLoading && (total > 0 ? `Result${total > 1 ? 's ' : ' '} for "${keyword || suggested}" ` : `Sorry, no results have been found${keyword ? ` for "${keyword}"` : ''}`)
                }
              /> : <Loading />
              }
              {total > 0 &&
                <div className='container p-12'>
                  <Typography className="text-14 cursor-pointer font-sans text-center md:text-left no-underline pb-8 pt-0">
                    <span className="text-grey-darker mr-4 cursor-pointer "> Refine search results </span>
                  </Typography>
                  <SearchFilters
                    onChange={this.filterContent.bind(this)}
                    onLoad={this.handleFilterLoaded.bind(this)}
                  />
                </div>
              }
              {(title || subtitle) &&
              <HeaderText
                small
                title={title}
                subtitle={subtitle}
              />
              }
              {_data &&
                  <Grid container className={classNames(classes.root, !isSlider && "container search-list-wrapper")} spacing={24} justify="flex-start">
                    {_data.length > 0 && _data.map(content => (
                          <Grid key={content.uob_deal_id}  item xs={12} md={6} lg={4} >
                            <Card
                              key={content.uob_deal_id}
                              {...this.props}
                              content={content}
                            />
                          </Grid>
                  ))}
                    {loadMore && _data.length > 0 && (total ? (_data.length < total) : true) && (
                      <Grid container justify="center" alignItems="center">
                        {isLoadingMore ?
                            <div className="p-20">
                              <Loading />
                            </div>
                          :
                          (hasMore &&
                            <Button
                              onClick={this.loadMoreBtnClick.bind(this)}
                              className={classNames("my-20 tracking-wide button-outlined-black p-12 pb-8 px-16 uppercase leading-none border-3 border-solid border-grey-darkest font-bold lg:text-18 sm:text-14 xs:text-12 text-grey-darkest") }
                            >
                              {loadMoreText || "Load More"}
                              <ExpandMoreIcon />
                            </Button>
                          )
                        }
                      </Grid>
                    )}
                  </Grid>
              }
              { _data && total === 0 && featuredDealData && (
                <div>
                  <Grid item justify="center" alignItems="center" className="flex w-full container my-48">
                      <Typography className="text-24 font-sans text-grey-dark self-center ">
                          You may like these deals instead
                      </Typography>
                  </Grid>
                  <Grid container className={classNames(classes.root, !isSlider && "container")} spacing={24} justify="flex-start">
                    {featuredDealData.map((content) =>
                      <Grid key={content.uob_deal_id}  item xs={12} md={6} lg={4} >
                        <Card
                          key={content.uob_deal_id}
                          {...this.props}
                          content={content}
                          dataTrack={`Recommender search >> ${content.type} >> ${content.title}`}
                        />
                      </Grid>
                    )}
                  </Grid>
                </div>
              )}
              {total > 0 && total < 9 && relatedCruisineData && (
                _.map(relatedCruisineData, ({ data, tag }, i) => {
                  let title = (titleText[i] || titleText.default)
                    .replace(/\$tagname/g, tag.name);

                  return data.length > 0 && (
                    <div className="search-title-small-wrapper">
                      <HeaderText
                        small
                        title={title}
                        className="search-title-small"
                      />
                      {!utils.isMobile()
                      ? <Grid container className={classNames(classes.root, !isSlider && "container")} spacing={24} justify="flex-start">
                        {data.map((content) =>
                          <Grid
                            key={content.uob_deal_id}
                            item
                            xs={12}
                            md={6}
                            lg={4}
                            className={classes.halfWidth}
                          >
                            <Card
                              key={content.uob_deal_id}
                              {...this.props}
                              content={content}
                              tag={tag}
                            />
                          </Grid>
                        )}
                      </Grid>
                      : <Slider
                          className="w-full"
                          {...pagerSettings}
                        >
                          {data.map((content) =>
                            <Grid key={content.uob_deal_id} className={classes.maxWidth300} >
                              <Card
                                key={content.uob_deal_id}
                                {...this.props}
                                content={content}
                                tag={tag}
                              />
                          </Grid>
                          )}
                        </Slider>
                      }
                  </div>
                  )
                })
              )}
            </div>
      );
    }
}


function mapStateToProps({scroll})
{
    return {
      scroll: scroll.data
    }
}

SearchResult.defaultProps = {
  suggested: '',
};

export default withStyles(styles, {withTheme: true})(withRouter(connect(mapStateToProps)(SearchResult)));
