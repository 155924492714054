import React, { Component } from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core';
import Loading from 'components/Elements/Loading';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Card from 'components/Cards/Card';
import HeaderText from 'components/Elements/HeaderText';
import SubHeaderText from 'components/Elements/SubHeaderText';
import API from 'configs/ApiConfig';
import _ from 'lodash';
import { Link } from 'react-router-external';
import BreakpointsConfig from 'configs/BreakpointsConfig';
import Slider from 'react-slick';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import('slick-carousel/slick/slick.css');
import('slick-carousel/slick/slick-theme.css');

const styles = theme => ({
  root: {},
  cardBorder: {
    marginRight: -1,
    border: 'none',
    [theme.breakpoints.up(BreakpointsConfig.lg)]: {
      '&:nth-child(4n)': {
        marginRight: 0,
        borderRight: 'none',
      },
      '&:nth-child(4n+1)': {
        borderLeft: 'none',
      },
      '&:nth-child(n+5)': {
        marginTop: -1,
      },
    },
    [theme.breakpoints.down(BreakpointsConfig.md)]: {
      '&:nth-child(2n)': {
        marginRight: 0,
        borderRight: 'none',
      },
      '&:nth-child(2n+1)': {
        borderLeft: 'none',
      },
    },
    [theme.breakpoints.down(BreakpointsConfig.md)]: {
      position: 'relative',
      border: 'none',
      '&:nth-child(n+5)': {
        marginTop: 0,
      },
      '&:before': {
        content: '\'\'',
        position: 'absolute',
        bottom: 0,
        left: '50%',
        transform: 'translateX(-50%)',
        height: 1,
        width: 1023,
      //  background: '#E1E1E1',
        background: 'none',
      },
      '&:last-child': {
        borderBottom: '0 !important',
      },
    },
  },
  className: {
    marginRight: -1,
    [theme.breakpoints.down(BreakpointsConfig.md)]: {
      marginRight: 0,
    },
  },
  fullWidth: {
    width: '100%',
  },
  arrow: {
    position: 'absolute',
    top: '50%',
    transform: 'translate(0, -50%)',
    cursor: 'pointer',
    zIndex: '9999',
  },
  [theme.breakpoints.up(BreakpointsConfig.lg)]: {
    noBorderTop: {
      paddingTop: '140px !important',
      borderTop: 0,
      paddingBottom: '80px !important',
    },
    absoluteButton: {
      position: 'absolute',
      bottom: '30px',
      left: '0',
    },
  },
  [theme.breakpoints.down(BreakpointsConfig.md)]: {
    maxWidth308: {
      maxWidth: '308px !important',
    },
  },
});
class Cards extends Component {
    state = {
      isLoadingMore: false,
      _params: null,
      _data: null,
      _page: 0,
      hasMore: true,
    }

    componentWillMount() {
      const { loadMore, totalPages, data } = this.props;

      this.setState({
        _params: loadMore && _.merge(loadMore.params, {
          page: 1,
        }),
        _data: data || [],
        hasMore: !((totalPages && totalPages === 1)),
      });
    }

    componentWillReceiveProps() {
      const { scroll, loadMore } = this.props;
      const { hasMore, isLoadingMore } = this.state;

      if (scroll && scroll.top > 0.9 && loadMore && hasMore && !isLoadingMore) {
        this.delayedLoading();
      }
    }

    loadMoreBtnClick(e) {
      this.delayedLoading();
    }

    delayedLoading() {
      const { loadMore, delay } = this.props;
      const {hasMore, _params, _page, _data, isLoadingMore,} = this.state;

      if (!isLoadingMore && hasMore) {
        this.setState({
          isLoadingMore: true,
        });
      }

      setTimeout(() => {
        if (loadMore && loadMore.api && hasMore) {
          if (_page < _params.page) {
            API.get(`/${loadMore.api}`, { params: _params }).then((response) => {
              const tempData = _data;


              _.mapValues(response.data.data, (resData) => {
                if (!_.find(tempData, { id: resData.id })) {
                  tempData.push(resData);
                }
              });

              this.setState({
                _params: _.merge(_params, { page: _params.page + 1 }),
                isLoadingMore: false,
                _data: tempData,

              });

              if ((response.data.totalPages - 1) <= response.data.currentPage) {
                this.setState({
                  hasMore: false,
                });
              }
            });

            this.setState({
              _page: _page + 1,
            });
          }
        }
      }, delay || 3000);
    }

    render() {
      const {
        classes, data, title, subtitle, subtitleCollapse, filters, children, total, smalltitle,
        loadMore, readMore, readMoreName, category, origin, loadMoreText, emptyMessage, haveBorder, isSlider, noBorder, absoluteBorder, isMaxWidth,
      } = this.props;

      const pagerSettings = {
        dots: false,
        infinite: true,
        speed: 500,
        focusOnSelect: true,
        swipeToSlide: true,
        centerMode: true,
        arrows: false,
      };

      const { hasMore, _data, isLoadingMore } = this.state;

      const renderCards = () => (
        isSlider
          ? (
            <Slider
              className={classes.fullWidth}
              {...pagerSettings}
            >
              {
                _data.map(content => (
                  <Card
                    key={content.id}
                    {...this.props}
                    content={content}
                  />
                ))
              }
            </Slider>
          )
          : _data.map(content => (
            <Grid key={content.id} xs={12} md={6} lg={3} className={classNames(haveBorder && classes.cardBorder, absoluteBorder && classes.noBorderTop)}>
              <Card
                key={content.id}
                {...this.props}
                content={content}
              />
            </Grid>
          ))
      );

      const defaultEmptyMessage = (
        <div>
          <Typography className="text-24 text-center font-serif text-grey-dark self-center">
              Ooops! There must be some missed steak!
          </Typography>
          <Typography className="text-18 text-center font-sans text-grey-dark self-center">
            {"We're looking into it. Try refreshing the page or select another option."}
          </Typography>
        </div>
      );
      const isWhatHot = category === "whats-hot";
      return (
        <div className={classNames(!absoluteBorder && 'md:my-20', haveBorder && classes.className)}>

          {(title || subtitle)
              && (
                <HeaderText
                  small
                  title={title}
                  subtitle={subtitle}
                  subtitleCollapse={subtitleCollapse}
                  noBorder={noBorder}
                />
              )
          }
          {smalltitle &&
              <SubHeaderText
                title={smalltitle}
                subtitle={subtitle}
              />
          }
          {filters
              && (
                <div className="my-28">
                  {filters}
                </div>
              )
          }

          {children && <div className={!absoluteBorder ? 'my-28' : 'margin-top'}>
            {children}
          </div>}

          {data
                  && (
                    <Grid container className={classNames(classes.root, !isSlider && 'container', isMaxWidth && classes.maxWidth308)} spacing={24} justify="flex-start">
                      {_data.length
                        ? renderCards()
                        : (
                          <Grid item justify="center" alignItems="center" className="flex flex-1 p-32 min-h-288">
                            <Typography className="text-24 font-sans text-grey-dark self-center">
                              {emptyMessage || defaultEmptyMessage}
                            </Typography>
                          </Grid>
                        )}

                      {loadMore && _data && _data.length > 0 && (total ? (_data.length < total) : true) && (
                        <Grid container justify="center" alignItems="center">
                          {isLoadingMore
                            ? (
                              <div className="p-20">
                                <Loading />
                              </div>
                            )
                            : (hasMore
                            && (
                              <Button
                                onClick={this.loadMoreBtnClick.bind(this)}
                                className={classNames('my-20 button-outlined-black tracking-wide p-12 pb-8 px-16 uppercase leading-none border-3 border-solid border-grey-darkest font-bold lg:text-18 sm:text-14 xs:text-12 text-grey-darkest ')}
                              >
                                {loadMoreText || 'Load More'}
                                <ExpandMoreIcon />
                              </Button>
                            )
                            )
                          }
                        </Grid>
                      )}

                      {readMore && !loadMore && (
                        <Grid container justify="center" alignItems="center" className={absoluteBorder && classes.absoluteButton}>
                          <Button
                            component={Link}
                            to={readMore}
                            className={classNames('my-12 tracking-wide p-12 pb-8 px-16  uppercase leading-none border-3 border-solid border-grey-darkest font-bold lg:text-18 sm:text-14 xs:text-12 text-grey-darkest no-underline button-outlined-black relative')}
                            data-track={isWhatHot ? `${origin} >> ${category}` : `${origin} >> ${category && (`${category} >> `)}${readMoreName || "read more"}`}
                          >
                            <span className="block font-bold">
                                {readMoreName || "READ MORE"}
                              <span
                                className="block absolute w-full h-full pin-t pin-l"
                                data-track={isWhatHot ? `${origin} >> ${category}` : `${origin} >> ${category && (`${category} >> `)}${readMoreName || "read more"}`}
                              />
                            </span>
                          </Button>
                        </Grid>
                      )}

                    </Grid>
                  )
          }

        </div>
      );
    }
}


function mapStateToProps({ scroll }) {
  return {
    scroll: scroll.data,
  };
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps)(Cards)));
