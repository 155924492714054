import {
  HeaderNavigationConfig,
  FooterNavigationConfig,
  SocialNavigationConfig,
  MainQuickPanelNavigationConfig,
  SubQuickPanelNavigationConfig,
} from 'configs/NavigationConfig';
import * as Actions from '../actions';

const headerInitialState = HeaderNavigationConfig;
const footerInitialState = FooterNavigationConfig;
const socialInitialState = SocialNavigationConfig;
const mainQuickPanelInitialState = MainQuickPanelNavigationConfig;
const subQuickPanelInitialState = SubQuickPanelNavigationConfig;

const headerNavigation = function (state = headerInitialState, action) {
  switch (action.type) {
    case Actions.GET_HEADER_NAVIGATION: {
      return [...state];
    }
    case Actions.SET_HEADER_NAVIGATION: {
      return [...action.navigation];
    }
    case Actions.RESET_HEADER_NAVIGATION: {
      return [...headerInitialState];
    }
    default: {
      return state;
    }
  }
};

const footerNavigation = function (state = footerInitialState, action) {
  switch (action.type) {
    case Actions.GET_FOOTER_NAVIGATION: {
      return [...state];
    }
    case Actions.SET_FOOTER_NAVIGATION: {
      return [...action.navigation];
    }
    case Actions.RESET_FOOTER_NAVIGATION: {
      return [...footerInitialState];
    }
    default: {
      return state;
    }
  }
};

const socialNavigation = function (state = socialInitialState, action) {
  switch (action.type) {
    case Actions.GET_SOCIAL_NAVIGATION: {
      return [...state];
    }
    case Actions.SET_SOCIAL_NAVIGATION: {
      return [...action.navigation];
    }
    case Actions.RESET_SOCIAL_NAVIGATION: {
      return [...socialInitialState];
    }
    default: {
      return state;
    }
  }
};

const mainQuickPanelNavigation = function (
  state = mainQuickPanelInitialState,
  action,
) {
  switch (action.type) {
    case Actions.GET_MAINQUICKPANEL_NAVIGATION: {
      return [...state];
    }
    case Actions.SET_MAINQUICKPANEL_NAVIGATION: {
      return [...action.navigation];
    }
    case Actions.RESET_MAINQUICKPANEL_NAVIGATION: {
      return [...socialInitialState];
    }
    default: {
      return state;
    }
  }
};

const subQuickPanelNavigation = function (
  state = subQuickPanelInitialState,
  action,
) {
  switch (action.type) {
    case Actions.GET_SUBQUICKPANEL_NAVIGATION: {
      return [...state];
    }
    case Actions.SET_SUBQUICKPANEL_NAVIGATION: {
      return [...action.navigation];
    }
    case Actions.RESET_SUBQUICKPANEL_NAVIGATION: {
      return [...socialInitialState];
    }
    default: {
      return state;
    }
  }
};

export {
  headerNavigation,
  footerNavigation,
  socialNavigation,
  mainQuickPanelNavigation,
  subQuickPanelNavigation,
};
