import React, { Component } from 'react';
import classNames from 'classnames';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import utils from 'utils';
import { disableScroll, enableScroll } from '../../Article/AnimationScroll';
import BreakpointsConfig from 'configs/BreakpointsConfig';
import { withStyles } from '@material-ui/core';

const styles = theme => ({
  item: {
    [theme.breakpoints.up(BreakpointsConfig.md)]: {
      minWidth: '25%',
    }
  },
});


class SelectAvatarMenu extends Component {
  componentWillUpdate() {
    if (utils.isMobile()) {
      const filter = document.querySelector('[aria-expanded="true"]');
      const header = document.querySelector('[data-header="true"]');
      const btnBackToTop = document.querySelector('.btn-back-to-top');
      if (filter && header) {
        header.style.display = 'none';
        btnBackToTop.style.display = 'none';
        disableScroll();
      }
    }
  }

  componentWillUnmount() {
    if (utils.isMobile()) {
      const filter = document.querySelector('[aria-expanded="false"]');
      const header = document.querySelector('[data-header="true"]');
      const btnBackToTop = document.querySelector('.btn-back-to-top');
      if (filter && header){
        header.style.display = 'block';
        btnBackToTop.style.display = 'block';
        enableScroll();
      }
    }
  }

  render() {
    const {
      isOpen,
      getMenuProps,
      getItemProps,
      selectedItems,
      highlightedIndex,
      getItems,
      inputValue,
      handleClearSelection,
      type,
      classes,
    } = this.props;

    return (
      <Paper
        {...getMenuProps({ isOpen })}
        elevation={1}
        className={classNames(
          'absolute pin-l bg-white w-full border-1 z-9999 rounded-none p-16 list-option',
          'border-solid border-grey-light border-t-0 p-0 cursor-pointer'
        )}
      >
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
        >
          {isOpen
            ? getItems(inputValue).map((item, index) => (
              <div className={classNames(classes.item)}>
                {item.slug && (item.image || item.picture)
                && (
                  <Grid
                    key={item.id}
                    item
                    container
                    direction="column"
                    alignItems="center"
                    selected={selectedItems && selectedItems.includes(item)}
                    className="pt-8 px-16 text-center relative"
                    xs
                    {...getItemProps({
                      item,
                      index,
                      isActive: highlightedIndex === index,
                      isSelected: selectedItems && selectedItems.includes(item),
                    })}
                  >
                    <Avatar
                      alt={item.name}
                      src={utils.resolveImageUrl(item.image || item.picture)}
                      className={classNames(
                        selectedItems && selectedItems.includes(item) && 'border-4 border-solid border-teal',
                        'w-128 h-128 border-4 border-transparent hover:border-4 hover:border-solid hover:border-teal filter-img-box'
                      )}
                    />

                    <Typography className={classNames('py-8 text-black text-12 text-center')}>
                      { item.name }
                    </Typography>
                    <div
                      className="absolute w-full h-full pin-t"
                      data-track={`${type} >> filter >> ${item.name}`}
                    />
                  </Grid>
                )
                }
              </div>
            ))
            : null}
        </Grid>
        <Button className="text-teal text-12 normal-case" onClick={e => handleClearSelection()}>
          Reset to All
        </Button>
      </Paper>
    );
  }
}

export default withStyles(styles, { withTheme: true }) (SelectAvatarMenu);
