import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import { Featured, Foodie101Series } from 'pages/guides/sections';
import Reviews from 'pages/reviews';
import Deals from 'pages/deals/carousel';
import utils from 'utils';
import BreakpointsConfig from 'configs/BreakpointsConfig';
import Highlights from './Highlights';
import queryString from 'query-string';
// import WhatsOn from 'pages/whatson/featured';
import WhatsOn from 'pages/whatson/featuredCarousel';

const styles = theme => ({
  root: {},
  section: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 3,
    paddingBottom: theme.spacing.unit * 2.5,
    [theme.breakpoints.up(BreakpointsConfig.md)]: {
      paddingTop: theme.spacing.unit * 2,
      paddingBottom: theme.spacing.unit * 2,
    },
  },
  stripe: {
    width: '100%',
    padding: '2rem 0',
    backgroundColor: '#159587',
    zIndex: 1400,
  },
});


class Home extends Component {
  state = {
    isHideStripe: !!window.localStorage.getItem('isHideStripe'),
  }

  handleClickInspire = () => {
    const { focusSearch, toggleSearchPanel } = this.props;
    focusSearch();
    if (utils.isMobile()) {
      toggleSearchPanel();
      setTimeout(() => {
        focusSearch();
      }, 100);
    }
    window.localStorage.setItem('isHideStripe', true);
  }

  componentDidUpdate() {
    // window._satellite.pageBottom();
    console.log(this.props.location.search);
  }

  render() {
    utils.triggerPageView();
    document.title = "Singapore's Ultimate Food Guide - The Dining Advisor";

    const { classes, className } = this.props;
    const  { isHideStripe } = this.state;

    return (
      <div className={classNames(classes.root, className)}>
        <Highlights />
        {
          isHideStripe ||
          <div className={classNames(classes.stripe, 'text-center md:text-left')}>
            <Grid container className="container">
              <Hidden smDown>
                <Grid item md={3} />
              </Hidden>
              <Grid item sm={12} md={4}>
                <Typography variant="inherit" className="fontPlayfairDisplayBlack text-white text-24 sm:text-32 font-400">
                  Not sure what to eat?
                </Typography>
                <Typography variant="inherit" className="fontAgendaMedium text-white text-16">
                  Try our recommender tool by clicking on the search!
                </Typography>
              </Grid>
              <Hidden mdUp>
                <Grid item sm={3} />
              </Hidden>
              <Grid item sm={6} md={2} className="sm:mt-16">
                <Button
                  fullWidth
                  onClick={this.handleClickInspire}
                  className="fontAgendaBold text-white text-16 rounded-none h-full border-white border-3 border-solid sm:py-12"
                >
                  INSPIRE ME
                </Button>
              </Grid>
            </Grid>
          </div>
        }
        <WhatsOn
        />
        <Reviews
          loadMore={false}
          showFilters={false}
          size={4}
          filterType="featured"
          readMore="reviews"
          category="reviews"
          smallHeaderText
          showTagOnCard={false}
          origin="home"
          sort="date_dsc"
          hideSocialReviewHome
          absoluteBorder
          page="home >> reviews"
        />
        <Featured
          title="New in Guides"
          subtitle={"No more hour-long discussions on where to eat. With our Foodie Guides, we've got you covered."}
          origin="home"
          category="featured"
        />
        <Foodie101Series
          className="container my-20"
          origin="home"
          category="food 101"
        />
        <Deals
          origin="home"
          category="deals"
          type="deals"
          subtitle={"You don't have to go for the cheapest thing on the menu. <br>Use your UOB Card for deals and discounts <br>on Singapore’s most delectable dishes."}
        />
      </div>
    );



  }
}

Home.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  focusSearch: PropTypes.func,
};

export default withStyles(styles, { withTheme: true })(withRouter(Home));
