import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-external';
import classNames from 'classnames';
import HeaderText from 'components/Elements/HeaderText';
import { Filter } from 'components/Filter';
import Button from '@material-ui/core/Button';
import { Next } from 'grommet-icons';
import Hidden from '@material-ui/core/Hidden';
import BreakpointsConfig from 'configs/BreakpointsConfig';

const styles = theme => ({
  root: {},
  borderButton: {
    border: '1px solid #E5E5E5',
  },
  [theme.breakpoints.down(BreakpointsConfig.md)]: {
    fontBold: {
      fontFamily: 'Agenda-Bold !important',
    },
  },
  [theme.breakpoints.up(BreakpointsConfig.md)]: {
    paddingButton: {
      paddingTop: '20px !important',
      paddingBottom: '5px !important',
    }
  }
});

const propTypes = {
  classes: PropTypes.string,
  match: PropTypes.object,
};
const defaultProps = {};

function GuidesHeader({ classes, match, }) {
  const bC = classNames('w-full h-full p-8 border-solid fontAgendaMedium border-0 rounded-none md:text-16 ', classes.fontBold, classes.paddingButton);
  const currCategoryParam = match.params.category;
  const classButtonWhat = {
    'border-active': currCategoryParam === 'whats-hot',
  };
  const classButtonFoodie = {
    'border-active': currCategoryParam === 'foodie-101',
  };
  const classButtonEat = {
    'border-active': currCategoryParam === 'the-eat-list',
  };
  const classButtonChefs = {
    'border-active': currCategoryParam === 'chefs-stories',
  };
  return (
    <div className={classNames(classes.root, " container md:px-56 lg:px-0 sm:px-0")}>

      <HeaderText
        title="Guides"
        subtitle={"No more hour-long discussions on where to eat. With our Foodie Guides, we've got you covered."}
        subtitleCollapse={"<br><br>Want to know how to eat like a foodie in Singapore? The Dining Advisor has just what you need to navigate the local dining landscape. We give you the latest scoop on Singapore's hottest dining spots, all from our crew of top foodies and chefs. We'll help you discover all there is to know about your favourite dishes, learn more about unconventional pairings, and even get tips from the pros. With our guides, you'll never run out of places to eat in Singapore – and you'll be a true in-the-know foodie, thanks to inspirational chef stories and the history behind their masterful creations. Can it get any better? It sure can – your UOB Card gets you the best deals, discounts and promotions for a fun foodie day, without breaking the bank. "}
      />

      <Filter
        filters={[
          (<Button
            className={classNames(bC, classButtonWhat, 'block pr-20 pl-20 color-1f1e18 text-14', classes.borderButton)}
            component={Link}
            to="/foodie-guides/categories/whats-hot"
          >
            <span data-track="guides home >> submenu >> what's hot">
              {"What's hot"}
            </span>
            <Hidden mdUp>
              <Next className="float-right arrow-color" width="18" height="18" />
            </Hidden>
          </Button>),
          (<Button
            className={classNames(bC, classButtonFoodie, 'block pr-20 pl-20 color-1f1e18 text-14', classes.borderButton)}
            component={Link}
            to="/foodie-guides/categories/foodie-101"
          >
            <span data-track="guides home >> submenu >> foodie 101">
              {'Food 101'}
            </span>
            <Hidden mdUp>
              <Next className="float-right arrow-color" width="18" height="18" />
            </Hidden>
          </Button>),
          (<Button
            className={classNames(bC, classButtonEat, 'block pr-20 pl-20 color-1f1e18 text-14', classes.borderButton)}
            component={Link}
            to="/foodie-guides/categories/the-eat-list"
          >
            <span data-track="guides home >> submenu >> the eat list">
              {'The Eat List'}
            </span>
            <Hidden mdUp>
              <Next className="float-right arrow-color" width="18" height="18" />
            </Hidden>
          </Button>),
          (<Button
            className={classNames(bC, classButtonChefs, 'block pr-20 pl-20 color-1f1e18 text-14', classes.borderButton)}
            component={Link}
            to="/foodie-guides/categories/chefs-stories"
          >
            <span data-track="guides home >> submenu >> chef stories">
              {'Chef Stories'}
            </span>
            <Hidden mdUp>
              <Next className="float-right arrow-color" width="18" height="18" />
            </Hidden>
          </Button>),
        ]}
      />

    </div>
  );
}

GuidesHeader.propTypes = propTypes;
GuidesHeader.defaultProps = defaultProps;

export default withStyles(styles, { withTheme: true })(withRouter(GuidesHeader));
