import React, { Component } from 'react';
import { withStyles } from '@material-ui/core';
import Hidden from '@material-ui/core/Hidden';
import utils from 'utils';
import classNames from 'classnames';
import { Scrollbars } from 'react-custom-scrollbars';
import ScrollToReadMore from './ScrollToReadMore';
import ArticleSocial from './Social';
import BreakpointsConfig from 'configs/BreakpointsConfig';
import Smooth, { scrollSmooth, enableScroll, verticalScroll } from './AnimationScroll';
import { setTimeout } from 'timers';
import { MainFooter } from 'components/Sections';


let _article = {
  smooth: null,
  contentScrollbars: null,
};


let stepsAnimation;
let stepsCounter = 0;
let stepsTimeout;

let scrollCounter = 0;

const styles = theme => ({
    // root: {
    //   // background: 'pink',
    //   // height: '100%',
    // },
    // background: {
    // absolute pin-t pin-l h-full
    //   // background: 'url(/assets/images/backgrounds/textures/marble.jpg)',
    //   // background: 'purple',
    //   backgroundPosition: 'top',
    // },
    // wrapper: {
    //   // 'height': '100%',
    //   // '& .bg-wrap': {
    //   //   transition: '0.87s cubic-bezier(0.52,0.01,0.16,1)',
    //   //   transform: 'translate3d(37%, 0, 0)',
    //   //   '& ~ .vs-section .title-large': {
    //   //     transition: 'all .5s ease',
    //   //   },
    //   //   '&[data-case-vid-state="left"], &[data-case-vid-state="full"]': {
    //   //     '&:after': {
    //   //       transform: 'scaleX(0)',
    //   //     }
    //   //   },
    //   //   '&[data-case-vid-state="right"]': {
    //   //     transform: 'translate3d(49.3%, 0, 0)',
    //   //     '& .bgImg': {
    //   //       transform: 'translate3d(-15%, 0, 0)',
    //   //     },
    //   //     '& ~ .vs-section .title-large': {
    //   //       opacity: 1,
    //   //       visibility: 'visible',
    //   //     }
    //   //   },
    //   //   '&[data-case-vid-state="full"]': {
    //   //     zIndex: 101,
    //   //     transform: 'translate3d(0, 0, 0)',
    //   //     '& .bgImg': {
    //   //       transform: 'translate3d(0, 0, 0)'
    //   //     },
    //   //     '& ~ .vs-section .title-large': {
    //   //       opacity: 0,
    //   //       visibility: 'hidden',
    //   //     },
    //   //   },
    //   //   '&[data-case-vid-state="left"]': {
    //   //     zIndex: 100,
    //   //     transform: 'translate3d(-63%, 0, 0)',
    //   //     '& .bgImg': {
    //   //       transform: 'translate3d(30%, 0, 0)'
    //   //     },
    //   //     '& ~ .vs-section .title-large': {
    //   //       opacity: 0,
    //   //       visibility: 'hidden',
    //   //     },
    //   //   },
    //   //   '& .bgImg': {
    //   //     zIndex: 2,
    //   //     transition: '0.87s cubic-bezier(0.52,0.01,0.16,1)'
    //   //   },
    //   //   '&:after': {
    //   //     content: '\'\'',
    //   //     width: '100%',
    //   //     height: '100%',
    //   //     position: 'absolute',
    //   //     top: '0',
    //   //     left: '0',
    //   //     backgroundColor: 'rgba(255,255,255,0.13)',
    //   //     zIndex: '3',
    //   //     transformOrigin: '0 0',
    //   //     transition: '0.95s cubic-bezier(0.52,0.01,0.16,1) 0.2s'
    //   //   },
    //   //   [theme.breakpoints.down(BreakpointsConfig.md)]: {
    //   //     position: 'absolute',
    //   //     transform: 'none',
    //   //     transition: 'unset',
    //   //     '&[data-case-vid-state="right"]': {
    //   //       transform: 'none',
    //   //     },
    //   //   }
    //   // },
    //   // '& .vs-section': {
    //   //   position: 'absolute',
    //   //   top: 0,
    //   //   left: 0,
    //   //   height: 'auto',
    //   //   width: '100%',
    //   //   maxWidth: '100%',
    //   //   // overflow: 'hidden',
    //   //   zIndex: 100,
    //   //   '& .animation-overlay': {
    //   //     '&:before': {
    //   //       content: '\'\'',
    //   //       position: 'absolute',
    //   //       top: 0,
    //   //       left: 0,
    //   //       width: '100%',
    //   //       height: '100%',
    //   //       animation: 'overlay 1.25s ease forwards',
    //   //     }
    //   //   },
    //   //   '& .footer': {
    //   //     position: 'absolute',
    //   //     bottom: 0,
    //   //     left: 0,
    //   //     width: '100%',
    //   //   },
    //   //   [theme.breakpoints.down(BreakpointsConfig.md)]: {
    //   //     position: 'relative',
    //   //   }
    //   // },
    //   // '& .right-content': {
    //   //   float: 'right',
    //   //   overflow: 'hidden',
    //   //   width: '63%',
    //   //   transition: '1.25s cubic-bezier(0.52,0.01,0.16,1)',
    //   //   background: 'blue',
    //   //   [theme.breakpoints.up(BreakpointsConfig.md)]: {
    //   //     width: '100%',
    //   //   },
    //   //   '& .content-wrap': {
    //   //     [theme.breakpoints.up(BreakpointsConfig.md)]: {
    //   //       transform: 'translateY(20vh)',
    //   //     },
    //   //   },
    //   //   '& .position-title-2': {
    //   //     visibility: 'visible',
    //   //     transition: 'visibility .5s ease',
    //   //   },
    //   //   '&[data-content-state="inactive"]': {
    //   //     '& .position-title-2': {
    //   //       visibility: 'hidden',
    //   //     },
    //   //     [theme.breakpoints.up(BreakpointsConfig.md)]: {
    //   //       // opacity: 0,
    //   //     }
    //   //   },
    //   //   '&[data-content-state="active"], &[data-content-state="active"] > div': {
    //   //     opacity: 1,
    //   //     // top: '64px',
    //   //     '& .content-wrap': {
    //   //       transform: 'translateY(0)',
    //   //     },
    //   //   },
    //   //   [theme.breakpoints.down(BreakpointsConfig.md)]: {
    //   //     paddingBottom: 64,
    //   //   },
    //   //   [theme.breakpoints.down(BreakpointsConfig.md)]: {
    //   //     float: 'none',
    //   //     width: '100%',
    //   //     transform: 'none',
    //   //   }
    //   // },
    //   // '& .title-wrap': {
    //   //   '& .animation-title': {
    //   //     position: 'relative',
    //   //     top: 'auto',
    //   //     transform: 'none',
    //   //     width: 'auto',
    //   //   },
    //   //   [theme.breakpoints.down(BreakpointsConfig.md)]: {
    //   //     display: 'none',
    //   //   },
    //   // },
    // },
    // bgWrap: {
    //   width: '100%',
    //   left: 0,
    //   top: 0,
    //   position: 'fixed',
    //   height: '100%',
    // },
    // titleWrap: {
    // flex justify-center h-full flex-col position-title-1  
    //   marginTop: '-0.5%',
    //   '& > div:first-child': {
    //     top: '10%',
    //     transform: 'none',
    //   }
    // },
    // hScreen: {
    //   width: '100wv',
    //   position: 'relative',
    //   height: 'calc(100vh - 64px)',
    //   // // height: 'calc(100vh)',
    //   // // background: 'cyan',
    //   // // [theme.breakpoints.down(BreakpointsConfig.lg)]: {
    //   // //   height: 'calc(100vh - 64px)',
    //   // // },
    //   // [theme.breakpoints.down(BreakpointsConfig.md)]: {
    //   //   height: 'calc(100vh - 64px)',
    //   //   '&.potrait': {
    //   //     height: 'calc(100vh - 64px)',
    //   //     '&[data-header-state="active"]': {
    //   //       height: 'calc(50vh)',
    //   //     },
    //   //   },
    //   // },
    //   // [theme.breakpoints.down(BreakpointsConfig.sm)]: {
    //   //   height: 'calc(100vh - 145px)',
    //   // },
    //   // '&.potrait': {
    //   // //   height: 'calc(100vh - 64px)',
    //   //   '&[data-header-state="active"]': {
    //   //     height: 'calc(100vh - 400px)',
    //   //   },
    //   // },
    // },
    // rightContentInner: {
    //   // transition: '1.25s cubic-bezier(0.52,0.01,0.16,1)',
    //   // transform: 'translateX(0%)',
    //   paddingTop: '100px',
    //   [theme.breakpoints.up(BreakpointsConfig.md)]: {
    //     // maxWidth: 1000,
    //     // marginLeft: '37%',
    //   },
    //   background: 'red',
    // },
    // w74: {
    //   width: '74%',
    //   [theme.breakpoints.down(BreakpointsConfig.md)]: {
    //     display: 'none',
    //   },
    // },
    // fWidth: {
    //   [theme.breakpoints.down(BreakpointsConfig.md)]: {
    //     width: '100%',
    //   },
    // },
    // hideSocial: {
    // absolute pin-t pin-l h-full md:w-2/3 lg:w-2/3
    //   '& div[data-social="true"]': {
    //     [theme.breakpoints.down(BreakpointsConfig.md)]: {
    //       display: 'none',
    //     },
    //   },
    // },
    // bannerImg: {
    //   width: '100%',
    //   height: '100%',
    //   position: 'absolute',
    //   top: 0,
    //   left: 0,
    //   [theme.breakpoints.down(BreakpointsConfig.md)]: {
    //     height: 'calc(100vh - 145px)',
    //   },
    // },


    animationPage: {
        // height: 'calc(100vh - 147px)',




        '&.desktop': {
            [theme.breakpoints.up(BreakpointsConfig.md)]: {

                '& .headerWrapper': {
                    position: 'fixed',
                    height: 'calc(100vh - 64px)',
                    top: 64,
                    paddingTop: '2%',
                 },

                '& .headerInner': {
                    paddingTop: 0,
                    
                 },

                 '& .imgOuter': {
                    height: 'calc(100vh - 64px)',
                    position: 'fixed',
                    top: 64,
                    background: 'url(/assets/images/backgrounds/textures/marble.jpg)',

                 },

                 '& .imgWrapper': {
                    height: 'calc(100vh - 64px)',
                    right: '-50%',
                    left: 'auto',
                },

                '& .imgOverlay': {
                    // [theme.breakpoints.up(BreakpointsConfig.md)]: {
                        background: 'rgba(0,0,0,0.2)',
                        left: '100%',
                    // },
                },
                '& .contentWrapper': {
                    // [theme.breakpoints.up(BreakpointsConfig.md)]: {
                        width: '63%',
                        marginLeft: 'auto',
                        paddingLeft: 15,
                        paddingTop: 'calc(100vh - 64px)',
                        opacity: 0,
                    // },
                },

                '& .readmoreWrapper': {
                    // [theme.breakpoints.up(BreakpointsConfig.md)]: {
                        display: 'block',
                    // },

                    '& div[data-social="true"]': {
                        display: 'block',
                    },

                    '& > div': {
                        bottom: 50,
                    },
                },



            },

            '&.potrait': {
                [theme.breakpoints.up(BreakpointsConfig.md)]: {

                    '& .headerWrapper': {
                        paddingTop: '10%',
                     },




                },
            }
        },


        '&.mobile': {
            '&.landscape': {
                '& .headerWrapper': {
                    paddingTop: 15,
                    [theme.breakpoints.up(BreakpointsConfig.md)]: {
                        // position: 'relative',


                    },
                },

                '& .headerInner': {
                    '& .animation-title': {
                        position: 'relative',
                    },
                    '& .text-white': {
                        color: '#fff',
                    },

                },
                '& .readmoreWrapper': {
                    '& .text-white': {
                        color: '#fff',
                    },

                    [theme.breakpoints.up(BreakpointsConfig.md)]: {
                        // position: 'relative',

                        '& div[data-social="true"]': {
                            display: 'block',
                        },

                        '& > div': {
                            display: 'flex',
                            flexDirection: 'column-reverse',
                            // position: 'relative',
                            bottom: -52,
                        },
                    },
                },
                '& .contentWrapper': {

                    [theme.breakpoints.up(BreakpointsConfig.md)]: {
                        paddingTop: 40,

                    },
                },

            },
            '& .headerInner': {
                '& h1': {
                        color: '#fff',

                },

            },
            // '& .headerWrapper': {
            //     paddingLeft: 15,
            //     paddingRight: 15,

            // },
            // '& .readmoreWrapper ': {
            //     paddingLeft: 15,
            //     paddingRight: 15,

            // },
        },

        





        '&.desktop[data-step="2"]': {
            [theme.breakpoints.up(BreakpointsConfig.md)]: {
                '& .desktop': {

                },
                '& .imgOuter': {
                    // position: 'fixed',
                    // background: 'none',
                },
                '& .imgWrapper': {
                    right: 0,
                },

                '& .imgOverlay': {
                    left: 0,
                },

                '& .headerWrapper': {
                    // position: 'relative',
                    // height: 'auto',
                    // width: '63%',
                    // marginLeft: 'auto',
                    paddingTop: '100vh',
                    opacity: 0,
                    zIndex: 2,
                },

                '& .contentWrapper': {
                    paddingTop: 40,
                },

            },
        },

        '&.desktop[data-step="3"]': {
            [theme.breakpoints.up(BreakpointsConfig.md)]: {
                '& .imgOuter': {
                    // position: 'fixed',
                    top: 64,
                    background: 'none',
                },
                '& .imgWrapper': {
                    right: '63%',

                },
                '& .imgOverlay': {
                    left: '-63%',
                },
                '& .headerWrapper': {
                    position: 'relative',
                    height: 'auto',
                    width: '63%',
                    marginLeft: 'auto',
                    paddingTop: '100vh',
                    opacity: 1,
                    paddingLeft: 15,
                },
                '& .headerInner': {
                    // paddingTop: 'calc(100vh - 64px)',
                    // paddingTop: 'calc(50vh - 64px)',

                    '& > div': {
                        position: 'relative',
                    }
                },
                '& .readmoreWrapper': {
                    display: 'none',
                },
                '& .contentWrapper': {
                    opacity: 1,
                    paddingTop: 40,
                },
            },
        },

    },

    headerWrapper: {
        height: 'calc(100vh -  147px)',
        position: 'absolute',
        top: 0,
        width: '100%',
        // maxWidth: 1000,
        paddingTop: '10%',
        zIndex: 4,
        transition: 'opacity 0.5s',
        pointerEvents: 'none',
        minHeight: 400,



    },
    headerInner: {
        // padding: '10% 15px 15px',
        paddingTop: 30,
        paddingBottom: 15,
        position: 'relative',
        // height: '100%',
        transition: 'opacity 0.5s',

        [theme.breakpoints.up(BreakpointsConfig.md)]: {
            // paddingTop: 'calc(100vh - 64px)',
        },
    },
    readmoreWrapper: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        transition: 'all 0.5s',
        pointerEvents: 'all',

        '& div[data-social="true"]': {
            display: 'none',
        }
    },
    imgOuter: {
        position: 'relative',
        width: '100vw',
        height: 'calc(100vh - 147px)',
        zIndex: 3,
        pointerEvents: 'none',
        transition: 'all 0.5s',
        minHeight: 400,


    },
    imgWrapper: {
        pointerEvents: 'none',
        minHeight: 400,
        width: '100vw',
        height: 'calc(100vh - 147px)',
        // background: 'linear-gradient(rgba(0,0,0,0.25), rgba(0,0,0,0.25))',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        transition: 'all 0.5s',

        position: 'absolute',
        top: 0,
        right: 0,
        left: 0,
    },
    imgOverlay: {
        background: 'rgba(0,0,0,0.4)',
        position: 'absolute',
        left: 0,
        // right: 0,
        top: 0,
        bottom: 0,
        pointerEvents: 'none',
        transition: 'all 0.5s',
        width: '100vw',



    },
    contentWrapper: {
        width: '100%',
        // maxWidth: '1000px',
        marginLeft: 'auto', 



    },
    rightContentInner: {
        maxWidth: '1000px',

    },
});
class ArticlePage extends Component {

  handleClick(evt) {
    // if (window.innerWidth < 768) {
      document.querySelectorAll('[data-social]')[1].scrollIntoView({
        behavior: 'smooth'
      });
      return;
    // }

    // if (!_article.smooth) {
    //   return;
    // }

    evt.preventDefault();
    // let scrollVal = +evt.currentTarget.dataset.scroll;
    // _article.smooth.scrollTo(scrollVal);
  }

  componentDidMount() {
    // console.log('Article - Page - componentDidMount');

    // let timeoutAnimation = null;
    // if (window.innerWidth >= 768) {
    //   const smoothScrollEase = 0.1;
    //   const section = document.querySelector('.vs-section');

    //   if (section) {
    //     _article.smooth = new Smooth({
    //       direction: 'vertical',
    //       section,
    //       ease: smoothScrollEase
    //     });
    //     // scrollSmooth();
    //     _article.smooth.init();
    //   }

    //   document.addEventListener('expanded', () => {
    //     console.log('Article - Page - componentDidMount - expanded');
    //     setTimeout(() => {
    //       _article.smooth.calc(verticalScroll.event);
    //       _article.smooth.run();
    //     }, 480);
    //   });

    // } else {
    //   // const animationImg = document.querySelector('.animation-load');
    //   // const { bannerImage } = this.props;
    //   // const image = document.createElement('img');
    //   // image.src = utils.resolveImageUrl(bannerImage);
    //   // image.onload = function() {
    //   //   clearTimeout(timeoutAnimation);
    //   //   timeoutAnimation = setTimeout(function() {
    //   //     animationImg.classList.add('animation-overlay');
    //   //   }, 300);
    //   // };
    // }

    const scrollElem = document.querySelector('.mainScrollbar > div');

    scrollElem.addEventListener('scroll', this.handleContentScroll);


    // stepsAnimation = window.requestAnimationFrame(this.handleRequestAnimationFrame);



    // scrollElem.onScroll()


        // console.log(scrollElem.scrollTop());
    // scrollElem.addEventListener('scroll', () => {
    //     console.log('scrollElem - scroll');

    // })

    // scrollElem.addEventListener('click', () => {
    //     console.log('scrollElem - click');

    // })

  }

  handleContentScroll = (e) => {

    // console.log('handleContentScroll', scrollCounter);
    scrollCounter += 1;
    // if (window.innerWidth >= 768 && window.handleContentScroll) {
    //   window.requestAnimationFrame(() => window.handleContentScroll(e));
    // }

    // const elem = document.querySelector('.mainScrollbar');
    // const scrollElem = e.target;

    this.updateSteps();

  }


  handleRequestAnimationFrame = () => {

    // console.log('handleRequestAnimationFrame', stepsCounter);
    stepsCounter += 1;
    // if (window.innerWidth >= 768 && window.handleContentScroll) {
    //   window.requestAnimationFrame(() => window.handleContentScroll(e));
    // }

    // const elem = document.querySelector('.mainScrollbar');
    // const scrollElem = e.target;

    setTimeout(() => {
        this.updateSteps();
        stepsAnimation = window.requestAnimationFrame(this.handleRequestAnimationFrame);
    }, 500);


  }



  updateSteps = () => {

    const animElem = document.querySelector('.animationPage');

    // console.log(document.querySelector('.mainScrollbar > div'));
    let contentScrollTop = (document.querySelector('.mainScrollbar > div')).scrollTop;
    let wrapperHeight =  document.querySelector('.mainScrollbar').offsetHeight;

    // console.log('Article - Page - handleContentScroll', scrollElem.scrollTop, elem.offsetHeight);
    // console.log('Article - Page - handleContentScroll', document.querySelector('.desktop'));
    if (document.querySelector('.desktop') != null) {
        if (contentScrollTop < 0.25 * wrapperHeight) {
            // animElem.dataset.step = 1;
            animElem.setAttribute('data-step', 1);
        } else if (contentScrollTop < 0.5 * wrapperHeight) {
            // animElem.dataset.step = 2;
            animElem.setAttribute('data-step', 2);
        } else {
            // animElem.dataset.step = 3;
            animElem.setAttribute('data-step', 3);
        }

    }
  }



  componentWillUnmount() {
    // console.log('Article - Page - componentWillUnmount');

    window.cancelAnimationFrame(stepsAnimation);

    let footer = document.querySelectorAll('.footer:not(.clone)')[0];
    footer.style.display = '';
    enableScroll();
  }

  _renderRightContent = () => {
    const { classes, header, children } = this.props;
    // return (
    //   <div data-right-content-wrapper style={{ position: 'relative'}}>
    //   <div
    //     className={classNames(classes.rightContentInner)}>
    //     <div className="title-wrap position-title-2" data-position-title2="right">
    //       {header}
    //     </div>
    //     <div className="content-wrap">
    //       {children}
    //     </div>
    //   </div>
    //   <Hidden smDown>
    //     <MainFooter className="clone" />}
    //   </Hidden>
    // </div>
    // );

    return (
        <div className={classNames(classes.rightContentInner)}>
            <div className="content-wrap">
              {children}
            </div>
        </div>
    );


  }

  render() {

    // console.log('this.props', this.props);


    // const scrollElem = document.getElementsByClassName('mainScrollbar')[0];

    // // scrollElem.onScroll()


    //     // console.log(scrollElem.scrollTop());
    // scrollElem.addEventListener('scroll', () => {
    //     console.log('scrollElem - scroll');

    // })


    const { classes, bannerImage, header, article, baseLink, text, hideButt, type, title, } = this.props;
    let bgImage = {
      // position: 'relative',
      // zIndex: 2,
      // backgroundImage: `url(${utils.resolveImageUrl(bannerImage)})`,
      // backgroundRepeat: `no-repeat`,
      // backgroundSize: `cover`,
      // backgroundPosition: `center center`
      backgroundImage: `linear-gradient(rgba(0,0,0,0.25), rgba(0,0,0,0.25)), url(${utils.resolveImageUrl(bannerImage)})`,
    };

    let orientation = (window.innerWidth > window.innerHeight) ? 'landscape' : 'potrait';
    let device = (window.innerHeight < 700 || window.innerWidth < 700 ) ? 'mobile' : 'desktop';
    

    // console.log(orientation);

    // return (
    //   <div className={classNames("animation-page", classes.wrapper)}>
    //     <div className={classNames("fixed pin-t pin-l h-full w-full js-case-vid-monitor bg-wrap", classes.bgWrap)}
    //       data-case-vid-state="right"
    //       data-case-vid-overide="false"
    //     >
    //       <div style={bgStyle} className={classNames("", classes.bannerImg)} />
    //     </div>
    //     <div className={classNames("vs-section", classes.className, classes.contentWrapper)} data-speed="0.1">
    //       <div className={classNames(classes.root)} data-animation-root>
    //         <div className={classNames("title-large animation-load right-header", orientation, classes.hScreen)} data-header-state='active'>
    //           <div className={classNames("", classes.fWidth, classes.hideSocial)}>
    //             <div className={classNames("", classes.background, classes.w74)} />
    //             <div
    //               className={classNames('', classes.titleWrap)}
    //             >
    //               data-position-title1="left"
    //               {header}
    //               <ScrollToReadMore onScrollClick={this.handleClick.bind(this)}>
    //                 {article && <ArticleSocial className="mt-40" type={type} content={article} baseLink={baseLink} text={text} hideButt={hideButt} isHiddenLink={true} title={title}/>
    //                 }
    //               </ScrollToReadMore>
    //             </div>
    //           </div>
    //         </div>
    //         <div className='right-content'
    //           data-content-state="inactive"
    //           data-content-state-overide
    //         >
    //           <Scrollbars
    //             ref={(s) => {_article.contentScrollbars = s}}
    //             data-animation-disable
    //             onScrollFrame={this.handleContentScroll}
    //             style={{ 
    //               height: '100vh', 
    //               // marginBottom: '60px' 
    //             }}
    //           >
    //             {this._renderRightContent()}
    //           </Scrollbars>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // )


    return (
        <div className={classNames('animationPage', orientation, device, classes.animationPage)} data-step='1'>
            <div className={classNames(classes.vsSection)} data-speed="0.1">


                <div className={classNames('headerWrapper', classes.headerWrapper)}>
                    <div className={classNames('headerInner', classes.headerInner)}>
                        {header}
                    </div>

                    <div className={classNames('readmoreWrapper', classes.readmoreWrapper)}>
                        <ScrollToReadMore onScrollClick={this.handleClick.bind(this)}>
                            {article && <ArticleSocial className={classNames(classes.articleSocial)} type={type} content={article} baseLink={baseLink} text={text} hideButt={hideButt} isHiddenLink={true} title={title}/> }
                        </ScrollToReadMore>
                    </div>
                </div>

                <div className={classNames('imgOuter', classes.imgOuter)} >
                    <div style={bgImage} className={classNames('imgWrapper', classes.imgWrapper)} ></div>
                    <div className={classNames('imgOverlay', classes.imgOverlay)} ></div>                   
                </div>


                <div className={classNames('contentWrapper', classes.contentWrapper)}>
                    {this._renderRightContent()}
                </div>

            </div>
        </div>



    )



  }

}


export const article = _article;

export default withStyles(styles, { withTheme: true })(ArticlePage);
