import React, { Component } from 'react';
import classNames from 'classnames';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-external';
import utils from 'utils';
import Slider from 'react-slick';
import BreakpointsConfig from 'configs/BreakpointsConfig';
import { withStyles } from '@material-ui/core';
import('slick-carousel/slick/slick.css');
import('slick-carousel/slick/slick-theme.css');

const styles = theme => ({
  imgBox: {
    '@media (min-width: 1024px) and (max-width: 1024px)': {
      flexBasis: '35%',
    },
  },
  contentBox: {
    '@media (min-width: 1024px) and (max-width: 1024px)': {
      flexBasis: '65%',
    },
  },
});

const pagerSettings = {
  dots: false,
  infinite: true,
  speed: 500,
  focusOnSelect: true,
  swipeToSlide: true,
  arrows: false,
  variableWidth: true,
};
class ArticleOtherDeals extends Component {
  render() {
    const {
      className, title, contents, page, circular = false, baseLink = '', direction = 'column', isSlider, classes
    } = this.props;
    const isReviews = page === "review";
    return (
      <div className={classNames('my-32 lg:px-96 sm:px-20', className, isReviews && "mb-12 md:mb-32")}>
        {title
        && (
          <Typography
            component="h4"
            variant="inherit"
            className="fontPlayfairDisplayBlack text-16"
          >
            {title}
            <hr className="w-full border-1 border-solid border-grey-light" />
          </Typography>
        )
        }
        <Grid container className={classNames("items-start", isSlider && "mt-16", !isReviews ? "mb-20" : "pt-4", isSlider && utils.isIOS() && "overflow-x-auto flex-no-wrap")} spacing={24}>
          {isSlider
          ? utils.isIOS()
            ? contents.map(content => (
              <Grid
                item
                container
                className={isReviews && "items-center"}
                className={isReviews ? "max-width-140" : "max-width-210"}
              >
                <Grid
                  item
                  xs={12}
                  md={6}
                >
                  <Link
                    to={`${baseLink}/${content.advisor ? (`${content.advisor.slug}/`) : ''}${content.slug}`}
                    className="no-underline"
                  >
                    <div className="relative">
                      <Avatar
                        alt={content.title}
                        src={utils.resolveImageUrl(content.image || content.img || content.picture)}
                        className={classNames('w-128 h-128 mb-12 mx-auto',
                          circular ? '' : 'sm:w-192 xs:max-w-160 rounded-none')}
                      />
                      <div
                        className="absolute w-full h-full pin-t"
                        data-track={page && (`${page} >> ${!isReviews ? `${title} >> ` : ''}${content.title} >> nearby ${page}`)}
                      />
                    </div>
                  </Link>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={6}
                >
                  <div>
                    <Typography
                      component={Link}
                      to={`${baseLink}/${content.advisor ? (`${content.advisor.slug}/`) : ''}${content.slug}`}
                      variant="inherit"
                      className={`fontPlayfairDisplayBlack text-16 text-black md:px-12 no-underline ${isReviews && "text-20 md:text-16"}`}
                    >
                      <span data-track={page && (`${page} >> ${!isReviews ? `${title} >> ` : ''}${content.title} >> nearby ${page}`)}>
                        {content.title}
                      </span>
                    </Typography>
                    {content.advisor
                        && (
                          <Grid item>
                            <Typography
                              component={Link}
                              to={`/advisors/${content.advisor.slug}`}
                              variant="inherit"
                              className={classNames('text-teal text-black no-underline')}
                            >
                              <span className="text-black">by</span>&nbsp;
                              {content.advisor.name}
                            </Typography>
                          </Grid>
                        )
                      }
                  </div>
                </Grid>
              </Grid>
            ))
            : (<Slider
            className="w-full padding-left-15"
            {...pagerSettings}
          >
            {contents.map(content => (
              <Grid
                item
                container
                className={isReviews && "items-center"}
                className={isReviews ? "max-width-140" : "max-width-210"}
              >
                <Grid
                  item
                  xs={12}
                  md={6}
                >
                  <Link
                    to={`${baseLink}/${content.advisor ? (`${content.advisor.slug}/`) : ''}${content.slug}`}
                    className="no-underline"
                  >
                    <div className="relative">
                      <Avatar
                        alt={content.title}
                        src={utils.resolveImageUrl(content.image || content.img || content.picture)}
                        className={classNames('w-128 h-128 mb-12 mx-auto',
                          circular ? '' : 'w-full xs:max-w-160 rounded-none')}
                      />
                      <div
                        className="absolute w-full h-full pin-t"
                        data-track={page && (`${page} >> ${!isReviews ? `${title} >> ` : ''}${content.title} >> nearby ${page}`)}
                      />
                    </div>
                  </Link>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={6}
                >
                  <div>
                    <Typography
                      component={Link}
                      to={`${baseLink}/${content.advisor ? (`${content.advisor.slug}/`) : ''}${content.slug}`}
                      variant="inherit"
                      className={`fontPlayfairDisplayBlack text-16 text-black md:px-12 no-underline ${isReviews && "text-20 md:text-16"}`}
                    >
                      <span data-track={page && (`${page} >> ${!isReviews ? `${title} >> ` : ''}${content.title} >> nearby ${page}`)}>
                        {content.title}
                      </span>
                    </Typography>
                    {content.advisor
                        && (
                          <Grid item>
                            <Typography
                              component={Link}
                              to={`/advisors/${content.advisor.slug}`}
                              variant="inherit"
                              className={classNames('text-teal text-black no-underline')}
                            >
                              <span className="text-black">by</span>&nbsp;
                              {content.advisor.name}
                            </Typography>
                          </Grid>
                        )
                      }
                  </div>
                </Grid>
              </Grid>
            ))}
          </Slider>)
          : contents.map(content => (
            <Grid
              item
              container
              xs={6}
              md={(direction === 'row') ? 6 : (12 / contents.length)}
              className={isReviews && "items-center"}
            >
              <Grid
                item
                xs={12}
                md={7}
                className={circular && classes.imgBox}
              >
                <Link
                  to={`${baseLink}/${content.advisor ? (`${content.advisor.slug}/`) : ''}${content.slug}`}
                  className="no-underline"
                >
                  <div className="relative">
                    <Avatar
                      alt={content.title}
                      src={utils.resolveImageUrl(content.image || content.img || content.picture)}
                      className={classNames('w-128 h-128 mb-12 m3-auto other-img-box',
                        circular ? '' : 'w-full xs:max-w-160 rounded-none md:h-96 lg:h-128', circular && classes.imgBox)}
                    />
                    <div
                      className="absolute w-full h-full pin-t"
                      data-track={page && (`${page} >> ${!isReviews ? `${title} >> ` : ''}${content.title} >> nearby ${page}`)}
                    />
                  </div>
                </Link>
              </Grid>
              <Grid
                item
                xs={12}
                md={5}
                className={circular && classes.contentBox}
              >
                <div>
                  <Typography
                    component={Link}
                    to={`${baseLink}/${content.advisor ? (`${content.advisor.slug}/`) : ''}${content.slug}`}
                    variant="inherit"
                    className={`fontPlayfairDisplayBlack text-16 text-black md:px-12 no-underline ${isReviews && "lg:text-20 md:text-16"}`}
                  >
                    <span data-track={page && (`${page} >> ${!isReviews ? `${title} >> ` : ''}${content.title} >> nearby ${page}`)}>
                      {content.title}
                    </span>
                  </Typography>
                  {content.advisor
                      && (
                        <Grid item>
                          <Typography
                            component={Link}
                            to={`/advisors/${content.advisor.slug}`}
                            variant="inherit"
                            className={classNames('text-teal text-black no-underline md:px-12')}
                          >
                            <span className="text-black">by</span>&nbsp;
                            {content.advisor.name}
                          </Typography>
                        </Grid>
                      )
                    }
                </div>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(ArticleOtherDeals);
