import React, { Component } from 'react';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import BreakpointsConfig from 'configs/BreakpointsConfig';

const styles = theme => ({
  root: {},
  videoWrap: {
    position: 'relative',
    width: '100%',
  },
  videoItem: {
    height: 350,
    [theme.breakpoints.down(BreakpointsConfig.md)]: {
      height: 204,
    },
  },
  embedResponsive: {
    overflow: 'hidden',
    height: '100%',
    width: '100%',
  },
});

class ArticleVideo extends Component {
  render() {
    const { classes, content } = this.props;

    return (
      <div className={classNames('lg:px-96', classes.videoWrap)}>
        <div className={classNames(classes.videoItem)}>
          <iframe
            className={classNames(classes.embedResponsive)}
            src={`https://www.youtube.com/embed/${content}?enablejsapi=1`}
            allowFullScreen="true"
            allowscriptaccess="always"
            title="Youtube"
          ></iframe>
        </div>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(ArticleVideo);
