import _ from 'lodash';

export const GET_FAVOURITES = '[FAVOURITES] GET FAVOURITES';
export const TOGGLE_FAVOURITES = '[FAVOURITES] TOGGLE FAVOURITES';
export const ADD_FAVOURITES = '[FAVOURITES] ADD FAVOURITES';
export const REMOVE_FAVOURITES = '[FAVOURITES] REMOVE FAVOURITES';
export const CLEAR_FAVOURITES = '[FAVOURITES] CLEAR FAVOURITES';

const storageKeyName = 'DAUOBFavourites';

export function getFavourites() {
  const favourites = JSON.parse(localStorage.getItem(storageKeyName)) || {};
  let count = 0;

  _.mapValues(favourites, (value) => {
    count += value.length;
  });

  return dispatch => dispatch({
    type: GET_FAVOURITES,
    payload: favourites,
    count,
  });
}

export function toggleFavourites(item) {
  const favourites = JSON.parse(localStorage.getItem(storageKeyName)) || {};
  let count = 0;

  const faved = favourites
    && favourites[item.type]
    && _.find(favourites[item.type], { slug: item.slug });

  if (!faved) {
    if (!favourites[item.type]) {
      favourites[item.type] = [];
    }

    favourites[item.type].push(item);
  } else {
    if (favourites && favourites[item.type]) {
      _.remove(favourites[item.type], { slug: item.slug });
    }
    if (favourites && favourites[item.type] && !favourites[item.type].length) {
      delete favourites[item.type];
    }
  }

  _.mapValues(favourites, (value) => {
    count += value.length;
  });

  localStorage.setItem(storageKeyName, JSON.stringify(favourites));

  return dispatch => dispatch({
    type: TOGGLE_FAVOURITES,
    payload: favourites,
    count,
  });
}

export function addToFavourites(item) {
  const favourites = JSON.parse(localStorage.getItem(storageKeyName)) || {};
  let count = 0;
  const faved = favourites
    && favourites[item.type]
    && _.find(favourites[item.type], { slug: item.slug });

  if (!faved) {
    if (!favourites[item.type]) {
      favourites[item.type] = [];
    }

    favourites[item.type].push(item);
  }

  _.mapValues(favourites, (value) => {
    count += value.length;
  });

  localStorage.setItem(storageKeyName, JSON.stringify(favourites));

  return dispatch => dispatch({
    type: ADD_FAVOURITES,
    payload: favourites,
    count,
  });
}

export function removeFavourite(item) {
  const favourites = JSON.parse(localStorage.getItem(storageKeyName)) || {};
  let count = 0;

  if (favourites && favourites[item.type]) {
    _.remove(favourites[item.type], { slug: item.slug }); // remove from list
  }

  if (favourites && favourites[item.type] && !favourites[item.type].length) {
    delete favourites[item.type]; // is type empty?
  }

  _.mapValues(favourites, (value, key) => {
    _.mapValues(value, (valItem) => {
      if (valItem && !valItem.slug) _.remove(favourites[key], valItem);
    });
  });

  _.mapValues(favourites, (value) => {
    count += value.length;
  });

  localStorage.setItem(storageKeyName, JSON.stringify(favourites));

  return dispatch => dispatch({
    type: REMOVE_FAVOURITES,
    payload: favourites,
    count,
  });
}

export function clearFavourites() {
  localStorage.removeItem(storageKeyName);

  return dispatch => dispatch({
    type: CLEAR_FAVOURITES,
    payload: [],
    count: 0,
  });
}
