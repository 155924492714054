import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Downshift from 'downshift';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Backdrop from '@material-ui/core/Backdrop';
import SearchSuggestions from './SearchSuggestions';
import SearchLocations from './SearchLocations';
import DefaultSuggestions from './DefaultSuggestions';

const styles = theme => ({
  Downshift: {},
  inputWrap: {
    paddingLeft: '64px',
  },
  root: {
    height: '100%',
    width: '100%',
    margin: 'auto',
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  backdrop: {
    top: '64px',
  },
  searchIcon: {
    width: theme.spacing.unit * 8,
    height: 64,
    position: 'absolute',
    top: 0,
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    '& .icon-search': {
      maxWidth: '25px',
    },
  },
  searchButton: {
    height: '100%',
    'border-radius': '0',
    width: '135px',
    minWidth: '135px',
    border: 'none',
    color: 'white',
    '&.btn-black-custom': {
      '&:hover': {
        background: '#1f1e18!important',
      },
    },
    '&.btn-blue-custom': {
      '&:hover': {
        background: '#159587!important',
      },
    },
    '&.disableBtn': {
      cursor: 'default',
      pointerEvents: 'none',
    },
    '&.btn-close': {
      padding: '0',
      width: '35px',
      minWidth: '35px',
      background: '#666666',
    },
  },
  input: {
    width: '100%',
    fontSize: '1.8rem',
    wordWrap: 'break-word',
    lineHeight: '1em',
    outline: 0,
    whiteSpace: 'normal',
    minHeight: '2em',
    background: 'transparent',
    display: 'inline-block',
    boxShadow: 'none',
    paddingLeft: '64px',
    height: '100%',
    color: '#1f1e18',
    fontFamily: 'Agenda-Medium',
  },
  select: {
    width: '250px',
    'z-index': 99999,
    borderRadius: 0,
  },
  ul: {
    top: '64px',
    position: 'absolute',
    left: 0,
    backgroundColor: 'white',
    width: '100%',
    maxHeight: '20rem',
    overflowY: 'auto',
    overflowX: 'hidden',
    outline: '1px solid #ccc !important',
    transition: 'opacity .1s ease',
    borderRadius: '0 0 .28571429rem .28571429rem',
    boxShadow: '0 2px 3px 0 rgba(34,36,38,.15)',
    borderColor: 'transparent',
    borderTopWidth: '0',
    borderRightWidth: 1,
    borderBottomWidth: 1,
    borderLeftWidth: 1,
    borderStyle: 'solid',
    zIndex: 3,
    paddingLeft: theme.spacing.unit * 4,
    'list-style-type': 'none',
  },
  placeHolderText: {
    top: '50%',
    transform: 'translateY(-50%)',
  },
  underline: {
    textDecoration: 'underline',
    textDecorationColor: '#C7C7C5',
  },
});

class SearchBar extends React.Component {
  node = React.createRef();

  componentWillMount() {
    document.addEventListener('mousedown', this.handleClickOuter, false);
  }

  handleClickStripe = () => {
    const { onClickStripe, onBlur } = this.props;
    onClickStripe();
    onBlur();
  }

  handleClickOuter = (e) => {
    const { isOpenDropdown, onBlur } = this.props;
    if (this.node.current && !this.node.current.contains(e.target) && !isOpenDropdown ) {
      onBlur();
    }
  }

  render() {
    const {
      classes, textInput, isFocus, populars, selectedLocation, suggestions, locations, searchRef, isOpenDropdown,
      onClickSuggestion, onChangeLocation, onClickDefaultSuggestion, onInputChange, onSubmitSearch, onFocus, onOpenDropdown, onBlur,
    } = this.props;

    return (
      <form
        className={classes.Downshift}
        onSubmit={onSubmitSearch}
        ref={this.node}
      >
        <Downshift>
          {({ getInputProps, isOpen }) => (
            <div className={classes.root}>
              <div className={classes.root}>
                <div className={classes.searchIcon}>
                  <img src="/assets/images/search-icon.svg" alt="search icon" className="icon-search" data-track="Recommender >> search"/>
                </div>
                <div className={`relative mr-20 ${classes.inputWrap}`}>
                  <div
                    className={
                      classNames({
                        'invisible flex-1': isFocus,
                        'visible flex-1': !isFocus,
                      })
                    }
                  >
                    <Typography data-x className="text-16 font-bold leading-none">
                      <span data-track="Recommender >> search">
                        Search with any keyword
                      </span>
                    </Typography>
                    <Typography className="text-12 text-grey">
                      <span data-track="Recommender >> search">
                        Short-circuit your search for good food
                      </span>
                    </Typography>
                  </div>
                  <div className="absolute h-full w-full pin-t pin-l">
                    <input
                      className={classNames(
                        classes.input,
                        {
                          'text-transparent': !isFocus,
                        }
                      )}
                      {...getInputProps({
                        isOpen,
                        value: textInput,
                        onFocus,
                        onChange: onInputChange,
                        placeholder: isFocus ? 'Type a keyword...' : '',
                      })}
                      ref={searchRef}
                      data-track="Recommender >> textbox"
                    />
                  </div>
                </div>
                {
                  textInput === '' && isFocus
                  && (
                    <DefaultSuggestions
                      populars={populars}
                      onClickSuggestion={onClickDefaultSuggestion}
                    />
                  )
                }
                {
                  isFocus &&
                  <Backdrop classes={{ root: classes.backdrop }} open={isFocus} />
                }
                {
                  isFocus
                  && (
                    <div className={`px-20 h-full ${classes.select}`}>
                      <SearchLocations
                        isOpen={isOpenDropdown}
                        locations={locations}
                        selectedLocation={selectedLocation}
                        onClickDropdown={onOpenDropdown}
                        onChangeSelect={onChangeLocation}
                      />
                    </div>
                  )
                }
                {
                  isFocus
                  && (
                    <SearchSuggestions
                      suggestions={suggestions}
                      onClickSuggestion={onClickSuggestion}
                      highlight={textInput}
                    />
                  )
                }
              </div>
              {
                isFocus
                 && (
                   <div className="h-full flex">
                     <Button
                       type="submit"
                       className={classNames(
                         classes.searchButton,
                         'btn-blue-custom bg-teal text-white text-16 font-medium font-bold relative',
                         {
                           disableBtn: textInput.length < 3,
                         },
                       )}
                       data-track="Recommender >> search"
                     >
                      <span data-track="Recommender >> search">
                        Search
                      </span>
                      <span data-track="Recommender >> search" className="absolute w-full h-full pin-t pin-l"/>
                     </Button>
                     <Button
                       onClick={this.handleClickStripe}
                       className={`${classes.searchButton} btn-black-custom bg-black text-white text-16 font-bold md:min-w-128`}
                     >
                       <div>
                         <p className="fontAgendaRegular text-10 lowercase" data-track="Recommender >> inspire me">Not sure what to eat?</p>
                         <p className="fontAgendaBold tracking-wide text-16" data-track="Recommender >> inspire me">INSPIRE ME!</p>
                       </div>
                     </Button>
                     <Button
                       className={`${classes.searchButton} btn-close`}
                       onClick={onBlur}
                     >
                       <img src="/assets/images/close-btn.png" alt="close" />
                     </Button>
                   </div>
                 )
              }
            </div>
          )}
        </Downshift>
      </form>
    );
  }
}

function mapStateToProps({ search }) {
  return {
    keyword: search.keyword,
    isFocus: search.isFocus,
    suggestions: search.suggestions,
    location: search.location,
    popularSearches: search.popularSearches,
    locations: search.locations,
    inspired: search.inspired,
  };
}

SearchBar.propTypes = {
  classes: PropTypes.object,
  isOpen: PropTypes.bool,
  suggestions: PropTypes.array,
  populars: PropTypes.array,
  isFocus: PropTypes.bool,
  locations: PropTypes.array,
  selectedLocation: PropTypes.string,
  textInput: PropTypes.string,
  onChangeLocation: PropTypes.func,
  onClickSuggestion: PropTypes.func,
  onInputChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onClickDefaultSuggestion: PropTypes.func,
  onClickStripe: PropTypes.func,
  onSubmitSearch: PropTypes.func,
  toggleQuickPanel: PropTypes.func,
  toggleSearchPanel: PropTypes.func,
};

export default withStyles(styles, { withTheme: true })(
  withRouter(
    connect(
      mapStateToProps,
      null,
    )(SearchBar),
  ),
);
