import React from 'react';
import { Typography, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import BreakpointsConfig from 'configs/BreakpointsConfig';

const propTypes = {
  small: PropTypes.boolean,
  removeBottomPadding: PropTypes.boolean,
  classes: PropTypes.string,
  className: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  noBorder: PropTypes.boolean,
};

const defaultProps = {
  small: false,
  removeBottomPadding: false,
  noBorder: false,
};

const styles = (theme) => ({
  root: {},
  fontSize: {
    fontSize: 70,
    [theme.breakpoints.down(BreakpointsConfig.md)]: {
      fontSize: 30,
    }
  }
});

function HeaderText({
  classes, className, small, tag, removeBottomPadding, title, subtitle, subtitleCollapse, noBorder, isLarge, dataTrack,
}) {
  let titleComponent = small ? 'h2' : 'h1';
  
  const subTitleSize = small ? 'text-16' : 'text-18';
  const gutterBottomClass = removeBottomPadding ? 'pb-0' : '';
  const titleStyle= {
    'text-center font-serif': true,
    [classes.fontSize]: isLarge,
  };

  if (tag) {
    titleComponent = tag;
  }

  return (
    <div className={classNames(classes.root, 'py-20', gutterBottomClass, className)}>
      {title
        && (
          <Typography component={titleComponent} variant="inherit" className={classNames(titleStyle)}>
          <span data-track={dataTrack} dangerouslySetInnerHTML={{ __html: title }}></span>
            {!noBorder && <hr className="mx-auto my-0 w-36 border-t-3 border-teal" />}
          </Typography>
        )
      }
      {subtitle
        && (
          <Typography component="p" className={classNames(subTitleSize, 'pt-12 text-center font-sans sm:text-16 px-40 bb')}
          >
            <span data-track={dataTrack} dangerouslySetInnerHTML={{ __html: subtitle}} />
            { subtitleCollapse 
              && (
                <span class="collapseWrapper">
                  <span class="ellipses">...</span>
                  &nbsp;
                  <span class="collapse" dangerouslySetInnerHTML={{ __html: subtitleCollapse}} />
                  <a class="btn-expand" dangerouslySetInnerHTML={{ __html: "Read more"}} onClick={handleCollapse} />
                  &nbsp;
                  <a class="btn-collapse" dangerouslySetInnerHTML={{ __html: "Read less"}} onClick={handleCollapse} />
                </span>
              )
            }

          </Typography>
        )
      }
    </div>
  );
}


function handleCollapse(e) {
  const btnElem = e.target;
  // console.log("handleCollapse", btnElem.classList.contains('btn-collapse'), btnElem.classList.contains('btn-expand'), btnElem.closest('.collapseWrapper'));
  if (btnElem.classList.contains('btn-collapse')) {
    btnElem.closest('.collapseWrapper').classList.remove('show');
    // btnElem.setState({clicked: true});
  } else if (btnElem.classList.contains('btn-expand')) {
    btnElem.closest('.collapseWrapper').classList.add('show');
  }
}

HeaderText.propTypes = propTypes;
HeaderText.defaultProps = defaultProps;

export default withStyles(styles)(HeaderText);
