import Deals from './index';
import { DealsDetails } from './detail';
import React from 'react';
import { Redirect } from 'react-router';

export const DealsConfig = {
    settings: {
        layout: {
            config: {},
        },
    },
    routes: [
        {
            path: '/deals/:slug',
            component: DealsDetails,
        },
        {
            path: '/deals',
            component: Deals,
        },
        {
            path: '/homedining',
            component: (props) => <Redirect to={"/deals?category=online&category=takeaways"} />
        },
        {
            path: '/midautumn',
            component: (props) => <Redirect to={"/deals?category=mid-autumn"} />
        },
        {
            path: '/1for1',
            component: (props) => <Redirect to={"/deals?category=1-for-1"} />
        },
        {
            path: '/gourmetjapan',
            component: (props) => <Redirect to={"/deals?category=gourmet-japan"} />
        },
        {
            path: '/pandamart',
            component: (props) => <Redirect to={"/deals/foodpanda"} />
        },
        {
            path: '/CSW',
            component: (props) => <Redirect to={"/deals/cornerstone-wines"} />
        },
        {
            path: '/xmas',
            component: (props) => <Redirect to={"/deals?category=christmas"} />
        },
    ],
};
