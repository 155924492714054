import React from 'react';
import Downshift from 'downshift';
import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Actions from 'store/actions';

class MultiSelect extends React.Component {
  stateReducer = (state, changes) => {
    switch (changes.type) {
      case Downshift.stateChangeTypes.keyDownEnter:
      case Downshift.stateChangeTypes.clickItem:
        return {
          ...changes,
          highlightedIndex: state.highlightedIndex,
          isOpen: false,
        };
      default:
        return changes;
    }
  }

  removeItem = (item, cb) => {
    this.setState(({ selectedItems }) => {
      const newSelectedItems = selectedItems.filter(i => !_.isEqual(i, item));

      if (this.props.onChange) {
        this.props.onChange(newSelectedItems);
      }

      return {
        selectedItems: newSelectedItems,
      };
    }, cb);
  }

  getStateAndHelpers(downshift) {
    const { defaultValue: selectedItems } = this.props;
    const { getRemoveButtonProps, removeItem } = this;
    return {
      getRemoveButtonProps,
      removeItem,
      selectedItems,
      ...downshift,
    };
  }

  render() {
    const { render, children = render, ...props } = this.props;
    return (
      <Downshift
        {...props}
        stateReducer={this.stateReducer}
        selectedItem={null}
      >
        {downshift => children(this.getStateAndHelpers(downshift))}
      </Downshift>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      clearFilter: Actions.clearFilter,
    },
    dispatch,
  );
}

function mapStateToProps({ search }) {
  return {
    willClearFilter: search.willClearFilter,
  };
}

export default connect(
  mapStateToProps, mapDispatchToProps
)(MultiSelect);
