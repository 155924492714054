export const GET_HEADER_NAVIGATION = '[NAVIGATION][HEADER] GET NAVIGATION';
export const SET_HEADER_NAVIGATION = '[NAVIGATION][HEADER] SET NAVIGATION';
export const RESET_HEADER_NAVIGATION = '[NAVIGATION][HEADER] RESET NAVIGATION';

export const GET_FOOTER_NAVIGATION = '[NAVIGATION][FOOTER] GET NAVIGATION';
export const SET_FOOTER_NAVIGATION = '[NAVIGATION][FOOTER] SET NAVIGATION';
export const RESET_FOOTER_NAVIGATION = '[NAVIGATION][FOOTER] RESET NAVIGATION';

export const GET_SOCIAL_NAVIGATION = '[NAVIGATION][SOCIAL] GET NAVIGATION';
export const SET_SOCIAL_NAVIGATION = '[NAVIGATION][SOCIAL] SET NAVIGATION';
export const RESET_SOCIAL_NAVIGATION = '[NAVIGATION][SOCIAL] RESET NAVIGATION';

export const GET_MAINQUICKPANEL_NAVIGATION = '[NAVIGATION][MAINQUICKPANEL] GET NAVIGATION';
export const SET_MAINQUICKPANEL_NAVIGATION = '[NAVIGATION][MAINQUICKPANEL] SET NAVIGATION';
export const RESET_MAINQUICKPANEL_NAVIGATION = '[NAVIGATION][MAINQUICKPANEL] RESET NAVIGATION';

export const GET_SUBQUICKPANEL_NAVIGATION = '[NAVIGATION][SUBQUICKPANEL] GET NAVIGATION';
export const SET_SUBQUICKPANEL_NAVIGATION = '[NAVIGATION][SUBQUICKPANEL] SET NAVIGATION';
export const RESET_SUBQUICKPANEL_NAVIGATION = '[NAVIGATION][SUBQUICKPANEL] RESET NAVIGATION';

export function getHeaderNavigation() {
  return {
    type: GET_HEADER_NAVIGATION,
  };
}

export function setHeaderNavigation(navigation) {
  return {
    type: SET_HEADER_NAVIGATION,
    navigation,
  };
}

export function resetHeaderNavigation() {
  return {
    type: RESET_HEADER_NAVIGATION,
  };
}

export function getFooterNavigation() {
  return {
    type: GET_FOOTER_NAVIGATION,
  };
}

export function setFooterNavigation(navigation) {
  return {
    type: SET_FOOTER_NAVIGATION,
    navigation,
  };
}

export function resetFooterNavigation() {
  return {
    type: RESET_FOOTER_NAVIGATION,
  };
}

export function getSocialNavigation() {
  return {
    type: GET_SOCIAL_NAVIGATION,
  };
}

export function setSocialNavigation(navigation) {
  return {
    type: SET_SOCIAL_NAVIGATION,
    navigation,
  };
}

export function resetSocialNavigation() {
  return {
    type: RESET_SOCIAL_NAVIGATION,
  };
}

export function getMainQuickPanelNavigation() {
  return {
    type: GET_MAINQUICKPANEL_NAVIGATION,
  };
}

export function setMainQuickPanelNavigation(navigation) {
  return {
    type: SET_MAINQUICKPANEL_NAVIGATION,
    navigation,
  };
}

export function resetMainQuickPanelNavigation() {
  return {
    type: RESET_MAINQUICKPANEL_NAVIGATION,
  };
}

export function getSubQuickPanelNavigation() {
  return {
    type: GET_SUBQUICKPANEL_NAVIGATION,
  };
}

export function setSubQuickPanelNavigation(navigation) {
  return {
    type: SET_SUBQUICKPANEL_NAVIGATION,
    navigation,
  };
}

export function resetSubQuickPanelNavigation() {
  return {
    type: RESET_SUBQUICKPANEL_NAVIGATION,
  };
}
