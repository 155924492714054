import React from 'react';
import Loadable from 'react-loadable';
import Loading from 'components/Elements/Loading';
import api from 'configs/ApiConfig';

/*
  ReviewsFilters
  Page: Reviews
  Render filtering
*/
export const SearchFilters = Loadable.Map({
  loader: {
    cuisines: () => api.get('/cuisines').then(res => res.data.data),
    occasions: () => api.get('/occasions').then(res => res.data.data),
    budgets: () => api.get('/budgets').then(res => res.data.data),
    utils: () => import('utils'),
    filterComponent:() => import('components/Filter')
  },
  delay: 500,
  timeout: 10000,
  loading: (props) => (<Loading />),
  render: (loaded, props) => {
    const {onChange, onLoad} = props
    const Filter = loaded.filterComponent.Filter
    const SelectFilter = loaded.filterComponent.SelectFilter
    const cuisines = loaded.cuisines
    const occasions = loaded.occasions
    const budgets = loaded.budgets
    const isMobile = loaded.utils.default.isMobile()
    onLoad()

    return (
      <Filter className="search-filter-top" filters={[
        (<SelectFilter
          filterKey="cuisine"
          label={isMobile ? "Filter By Cuisine" : "By Cuisine"}
          items={cuisines}
          onChange={onChange}
          classNameLabel={isMobile && "fontAgendaBold text-14"}
        />),
        (<SelectFilter
          filterKey="occasion"
          label={isMobile ? "Filter By Occasion" : "By Occasion"}
          items={occasions}
          onChange={onChange}
          classNameLabel={isMobile && "fontAgendaBold text-14"}
        />),
        (<SelectFilter
          filterKey="budget"
          label={isMobile ? "Filter By Budget" : "By Budget"}
          items={budgets}
          onChange={onChange}
          classNameLabel={isMobile && "fontAgendaBold text-14"}
        />),
        (<SelectFilter
          filterKey="location"
          label={isMobile ? "Filter By Location" : "By Location"}
          allText="Anywhere"
          defaultValue={props.locationDefault}
          items={loaded.utils.default.getDefaultLocations()}
          onChange={onChange}
          classNameLabel={isMobile && "fontAgendaBold text-14"}
          primaryKey="id"
        />),

      ]} />
    )
  }
});
