import React, {Component} from 'react';
import classNames from 'classnames';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom'
import CardContent from '@material-ui/core/CardContent';
import {withStyles, Grid, Typography, IconButton} from '@material-ui/core';
import FavouritesBtn from 'components/Favourites/FavouritesBtn';
import Tag from 'components/Elements/Tag';
import {Link} from 'react-router-external';
import utils from 'utils';
import _ from 'lodash';
import BreakpointsConfig from 'configs/BreakpointsConfig';
import moment from 'moment';

const styles = theme => ({
    root  : {
      '&:hover': {
        '& .image': {
          borderRadius: 0
        }
      },
      '& a': {
        color: '#000 !important',
        display: 'block',
      }
    },
    circular: {
      borderRadius: '50%'
    },
    contained: {
      '& img': {
        width: '100%!important'
      }
    },
    iconMessenger: {
      width: 18,
      height: 18,
      [theme.breakpoints.down(BreakpointsConfig.md)]: {
        width: 15,
        height: 15,
      },
    },
    btnBorder: {
      borderRadius: 0,
      padding: 2,
      '&:hover': {
        borderRadius: '50%',
      },
      [theme.breakpoints.down(BreakpointsConfig.md)]: {
        borderRadius: 0,
      },
    },
    mt1: {
      [theme.breakpoints.down(BreakpointsConfig.md)]: {
        marginTop: 1,
      },
    },
    absoluteContent: {
      // position: 'absolute',
      // right: 0,
      // top: '23px',
      // [theme.breakpoints.down(BreakpointsConfig.md)]: {
      //   top: '39px',
      // },
      
    },
    marginTop: {
      position: 'relative',
      marginTop: '-15px',
    },
    paddingRight: {
      paddingRight: '35px',
      paddingTop: '18px',
    },
    reviewBy: {
      paddingRight: '35px',
      paddingTop: '18px',
    },
    tagHidden: {
      position: 'absolute',
      // top: 0,
      // left: 0,
      // width: '200px',
      background: '#159587',
      padding: '3px 15px',
      color: '#fff',
      // display: 'none',
      // '&::after': {
      //   'content': 'NEW',
      // }
    }
});

class CardDefault extends Component
{

  componentDidMount()
  {
      window.addthis && window.addthis.toolbox('.addthis_toolbox')
  }

  render()
  {
    const {classes, className, page, category, addthis, type, subtitleKey, hideSocial, content, circular, variant, children, showTagOnCard, showSubcategory, origin, hideSocialReviewHome, maxHeight, shareTrack, } = this.props
    const title = content.title || content.name
    const subtitle = (subtitleKey && content[subtitleKey]) || null
    const image = content.image || content.img || content.picture
    const imageURL = utils.resolveImageUrl(content.image || content.img || content.picture)
    const advisor = content.advisor_name || (content.advisor && content.advisor.name)
    const advisorSlug = content.advisor_slug || (content.advisor && content.advisor.slug) || '';
    const advisorUrl = (content.advisor_slug || (content.advisor && content.advisor.slug)) && ('/advisors/' + advisorSlug)
    const tagsFeatured = _.get(content,['tags', 'featured'])
    const isPos = utils.isMobile() && advisor;
    let variantClass = ""

    var bgInlineStyle = {
      background: `url("${decodeURI(imageURL)}")`,
      backgroundRepeat: `no-repeat`,
      backgroundSize: `cover`,
      backgroundPosition: `center center`,
      transition: 'all .3s ease-in-out'
    };

    const currentDate = moment();
    const articleDate = moment(content.date);
    const curpageurl = window.location.pathname;
    const newTag = (currentDate.diff(articleDate, 'days') <= 21);
    //const newTag = (currentDate.diff(articleDate, 'days') <= 21 && type=='reviews');
    //const newTag = (currentDate.diff(articleDate, 'days') <= 21 && curpageurl=='/reviews');
    switch(variant) {
      case 'contained':
        variantClass = classNames(classes.contained, "bg-white border-b-1 border-grey  mx-20")
        bgInlineStyle['width'] = '100%'
        break;
      case 'transparent':
        break;
      default:
        break;
    }
    const url = `${window.location.origin}/${type}${advisorSlug?'/'+advisorSlug: ''}/${content.slug}`;
    const isWhatHot = category === 'whats-hot';
    const isReviews = page === 'reviews home';
    return (
      <div className={classNames(classes.root, "overflow-hidden flex flex-col h-full font-serif justify-center items-stretch m-8", variantClass, className)} >
        <div className="flex-1 w-full" style={{flexBasis: 'auto'}}>
          {image &&
            <Link to={"/" + type + `${advisorSlug?'/'+advisorSlug: ''}`+"/" + content.slug}>
              <div
                data-track={`${origin} >> ${category && `${category} >> `}${title}`}
                className={classNames((circular && classes.circular),
                  "image cursor-pointer w-224 h-224 mx-auto",
                  (variant === 'transparent' ? "w-full lg:max-h-192 md:max-h-224 sm:max-h-192" : ""),
                  maxHeight && `w-full ${utils.isMobile() ? "max-height-120" : "max-height-150 min-height-150"}`,
                )}
                style={ bgInlineStyle }
              >
                {newTag &&
                  <div className={classNames(classes.tagHidden)}>NEW
                   
                  </div>
                }
              </div>
            </Link>
          }
          <div className={classNames(type==="deals" ? "self-start h-256" : "self-start")} data-track={`${origin} >> ${category && `${category} >> `}${title}`}>
            <Grid container alignContent="space-between" className={isPos && classes.marginTop}>
                <Grid item xs={hideSocial ? 12 : maxHeight ? 8 : 9}>
                    <CardContent
                      className={classNames(
                        hideSocial ? "" : (utils.isMobile() ? `px-0 ${maxHeight ? "pt-4 pl-0" : "lg:pt-20 md:pt-20  sm:pt-16"}` : `pr-0 pt-4 ${maxHeight && "pl-0"}`),
                        (variant === 'transparent' ? "p-0 mt-4" : "")
                      )}
                      >
                        {
                          showTagOnCard && tagsFeatured && (
                            <div className={isPos && classes.marginTop}>
                              <Tag text="FEATURED" dataTrack={`${origin} >> ${category && `${category} >> `}${title}`}/>
                            </div>
                        )}
                        {title && (
                          <div>
                            {showSubcategory && content.foodie_category.name &&
                              <Typography component="h5" variant="inherit" className="uppercase text-teal text-base leading-none font-sans pt-8">
                                <span className="block" data-track={`${origin} >> ${category && `${category} >> `}${title}`}>
                                  {content.foodie_category.name}
                                </span>
                              </Typography>
                            }
                            <Typography component={Link} to={"/" + type + `${advisorSlug?'/'+advisorSlug: ''}`+"/" + content.slug} variant="inherit" className={classNames("text-20 text-black no-underline font-serif text-left cursor-pointer break-words test", isPos && classes.paddingRight)} >
                                <span data-track={`${origin} >> ${category && `${category} >> `}${title}`}>{title}</span>
                            </Typography>
                          </div>
                        )}
                        {advisor && (
                          <Typography component={Link} to={advisorUrl} gutterBottom className={classNames("text-16 cursor-pointer font-sans text-left no-underline", isPos && classes.absoluteContent)}>
                              by <span className="text-teal cursor-pointer" data-track={`${page} >> ${advisor}`}> {advisor} </span>
                          </Typography>
                        )}
                        {subtitle && (
                          <Typography gutterBottom className="mt-8 text-15 text-grey-darker cursor-pointer font-sans text-left relative">
                            <span className="block" dangerouslySetInnerHTML={{ __html: _.truncate(subtitle, {length:174})}}/>
                            <span className="absolute pin-t pin-l w-full h-full" data-track={`${origin} >> ${category && `${category} >> `}${title}`}/>
                          </Typography>
                        )}
                        {children}
                    </CardContent>
                </Grid>
                {!hideSocial &&
                <Grid item xs={maxHeight ? 4 : 3} className="w-full self-start mt-4 md:mt-8 md:pt-0 sm:pt-32">
                    <Grid container alignContent="space-between">
                      {!hideSocialReviewHome && <Grid item xs={6} >
                          {addthis.isLoaded &&
                          <div className="addthis_toolbox block overflow-hidden" data-addthis-url={url}>
                            <a href="/#" className="addthis_button_compact relative float-right" >
                              <IconButton
                                aria-label="Share"
                                className={classes.btnBorder}
                              >
                                <img className={classNames(classes.iconMessenger)} src='/assets/images/icon-share.png' alt='icon share' />

                                <div
                                  className="absolute w-full h-full pin-t"
                                  data-track={isWhatHot ? `${origin} >> ${category} >> ${content.title} >> share` : `${isReviews ? page : type} >> ${content.title} >> share`}
                                />
                              </IconButton>
                            </a>
                          </div>
                          }
                        </Grid>}
                        <Grid item xs={6} >
                            <FavouritesBtn className={classNames("block overflow-hidden", classes.mt1)} type={type} page={page} item={content} hideSocialReviewHome={hideSocialReviewHome} isWhatHot={isWhatHot} origin={origin} category={category}/>
                        </Grid>
                    </Grid>
                </Grid>
                }
            </Grid>
          </div>
        </div>
      </div>
    )

  }
}

function mapStateToProps({addthis})
{
    return {
      addthis
    }
}

export default withStyles(styles, {withTheme: true})(withRouter(connect(mapStateToProps)(CardDefault)));
