import { AdvisorsList, AdvisorsDetails } from './index';

export const AdvisorsConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: '/advisors/:slug',
      component: AdvisorsDetails,
    },
    {
      path: '/advisors',
      component: AdvisorsList,
    },
  ],
};
