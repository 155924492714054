import API from 'configs/ApiConfig';
export const SET_SEARCH_ISFOCUS = '[SEARCH] SET SEARCH IS FOCUS';
export const GET_SEARCH_KEYWORD = '[SEARCH] GET SEARCH KEYWORD';
export const SET_SEARCH_KEYWORD = '[SEARCH] SET SEARCH KEYWORD';
export const GET_SEARCH_RESULTS = '[SEARCH] GET SEARCH RESULTS';
export const GET_SUGGESTION_SEARCH = '[SEARCH] GET SUGGESTION SEARCH';
export const REMOVE_SUGGESTION_SEARCH = '[SEARCH] REMOVE SUGGESTION SEARCH';
export const CHANGE_LOCATION_SEARCH = '[SEARCH] CHANGE_LOCATION_SEARCH';
export const SET_TOTAL_RESULT = '[SEARCH] SET_TOTAL_RESULT';
export const SET_FEATURED_DEAL = '[SEARCH] SET_FEATURED_DEAL';
export const SET_RELATED_CRUISINE = '[SEARCH] SET_RELATED_CRUISINE';
export const INIT_DATA = '[SEARCH] INIT_DATA';
export const SET_IS_LOADING = '[SEARCH] SET_IS_LOADING';
export const CLEAR_FILTER = '[SEARCH] CLEAR_FILTER';

export function setSearchFocus(isFocus) {
  return {
    type: SET_SEARCH_ISFOCUS,
    isFocus,
  };
}

export function getSearchKeyword() {
  return {
    type: GET_SEARCH_KEYWORD,
  };
}

export function setSearchKeyword(keyword) {
  return {
    type: SET_SEARCH_KEYWORD,
    keyword,
  };
}

export function getSearchResults(params) {
  const request = API.get(`/newsearch${params}`);
  return dispatch =>
    request.then(response =>
      dispatch({
        type: GET_SEARCH_RESULTS,
        results: response.data,
      }),
    );
}

export function getSuggestions(keyword) {
  const request = API.get(`/suggestedsearch?keyword=${keyword}`);
  return dispatch =>
    request.then(response =>
      dispatch({
        type: GET_SUGGESTION_SEARCH,
        results: response.data,
      }),
    );
}

export function removeSuggestion() {
  return {
    type: REMOVE_SUGGESTION_SEARCH,
  };
}

export function changeLocation(location) {
  return {
    type: CHANGE_LOCATION_SEARCH,
    location,
  };
}

export function setTotalResult(total) {
  return {
    type: SET_TOTAL_RESULT,
    total
  };
}

export function setRelatedCruisineData(data) {
  return {
    type: SET_RELATED_CRUISINE,
    data
  }
}

export function setFeaturedDealData(data) {
  return {
    type: SET_FEATURED_DEAL,
    data
  }
}

export function setLoading(isLoading) {
  return {
    type: SET_IS_LOADING,
    isLoading
  }
}

export function initData(location = '') {
  const requests = Promise.all([
    API.get('/popularsearches'),
    API.get('/locations'),
    API.get(`/inspiredme?${location}`)
  ]);
  return dispatch => {
    requests.then(responses =>
      dispatch({
        type: INIT_DATA,
        popularSearches: responses[0].data.data,
        locations: responses[1].data.data,
        inspired: responses[2].data
      })
    );
  }
}

export function clearFilter(willClear) {
  return {
    type: CLEAR_FILTER,
    willClear
  }
}
