import { GetFeatured } from './index';

export const GetFeaturedConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: '/get-featured',
      component: GetFeatured,
    },
  ],
};
