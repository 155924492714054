import React, { Component } from 'react';
import Loading from 'components/Elements/Loading';
import Loadable from 'react-loadable';
import api from 'configs/ApiConfig';
import utils from 'utils';
import { Redirect } from 'react-router';

const delay = 500;
const timeout = 10000;

export const GourmetStories = Loadable.Map({
  loader: {
    articleComponent: () => import('components/Article'),
    infoSection: () => import('./sections/info'),
    storiesPanels: () => import('./sections/stories'),
    contents: () => api.get('/gourmetstories').then(res => res.data),
  },
  delay,
  timeout,
  loading: props => <Loading {...props} />,
  render: (loaded, props) => {
    utils.triggerPageView();
    document.title = "UOB Gourmet Stories - The Dining Advisor";

    const ArticlePage = loaded.articleComponent.ArticlePage;
    const ArticleHeader = loaded.articleComponent.ArticleHeader;
    const ArticleContent = loaded.articleComponent.ArticleContent;
    const ArticleSocial = loaded.articleComponent.ArticleSocial;
    const GourmetInfo = loaded.infoSection.default;
    const GourmetStoriesExPanel = loaded.storiesPanels.default;


    const renderHeader = article => (
      <ArticleHeader
        label="What's Hot"
        title="UOB Gourmet Stories"
        subtitle="Inimitable meals with exclusive wine pairing specials."
      />
    );
    const title = loaded.contents.data.title || "UOB Gourmet Stories";

    return (
      <ArticlePage
        bannerImage="/storage/home-sliders/gourmet_stories_desktop.jpg"
        header={renderHeader(loaded.contents.data)}
        article={loaded.contents.data}
        hideButt="hidden"
        type="gourmet stories"
        title={title}
      >
        <ArticleSocial type="gourmet stories" className="mt-20" content={loaded.contents.data} baseLink="/foodie-guides" text="back to foodie guides" isHiddenButton
          hideButt="hidden"
          title={title}
          dataTrack={`gourmet stories >> ${title} >> Back to Gourmet Stories`}
        />
        <GourmetInfo
        caption={loaded.contents.external_link_caption}
        url={loaded.contents.external_link_url}
        />
        <ArticleContent>
          <GourmetStoriesExPanel data={loaded.contents.data} />
        </ArticleContent>
      </ArticlePage>
    );
  },

});

export const GourmetStory = class GourmetStory extends Component {
  render() {
    utils.triggerPageView();
    const LoadableComponent = Loadable.Map({
      loader: {
        articleComponent: () => import('components/Article'),
        infoSection: () => import('./sections/info'),
        menuSection: () => import('./sections/menu'),
        storiesPanels: () => import('./sections/stories'),
        tabs: () => import('@material-ui/core/Tabs'),
        tab: () => import('@material-ui/core/Tab'),
        typo: () => import('@material-ui/core/Typography'),
        content: () => api.get('/gourmetstory', { params: this.props.match.params })
          .then(res => res.data),
      },
      delay,
      timeout,
      loading: props => <Loading {...props} />,
      render: (loaded, props) => {
        if (loaded.content.status == "error") {
          return <Redirect to={"/404"} />
        }

        
        document.title = loaded.content.title + " - The Dining Advisor";
        const ArticlePage = loaded.articleComponent.ArticlePage;
        const ArticleHeader = loaded.articleComponent.ArticleHeader;
        const ArticleContent = loaded.articleComponent.ArticleContent;
        const ArticleSocial = loaded.articleComponent.ArticleSocial;
        const ArticleMap = loaded.articleComponent.ArticleMap;
        const ArticleProfile = loaded.articleComponent.ArticleProfile;
        const ArticleDropdown = loaded.articleComponent.ArticleDropdown;
        const GourmetInfo = loaded.infoSection.default;
        const Menu = loaded.menuSection.default;
        const article = loaded.content;
        const arrMap = article.outlets;
        let profile = {};
        let menuType = [];
        const menuContents = [];

        const renderHeader = article => (
          <ArticleHeader
            title={article.title}
          />
        );

        const setMenu = (content) => { menuContents.push(content); };
        const setType = (type) => {menuType.push(type); };
        const setProfile = (data) => { profile = data; };

        return (
          <ArticlePage
            bannerImage={article.image}
            header={renderHeader(article)}
            className="mb-32"
            article={article}
            type="gourmet stories"
          >
            <ArticleSocial type="gourmet stories" content={article} baseLink="/gourmet-stories" text="back to gourmet stories" isHiddenButton dataTrack={`gourmet stories >> ${article.title} >> Back to gourmet stories`}/>
            {article.content && article.content.map(content => (
              <span>
                {content.type === 0
                && (
                  <div className="p-0">
                    {content.content
                  && <ArticleContent contents={[content.content]} />
                    }
                  </div>
                )
                }
                {content.type === 14 && setProfile(content)}
                {content.type === 11 && (
                  setMenu(content.content),setType(content.type)
                )
                }
                {content.type === 12 && (
                  setMenu(content.content),setType(content.type)
                )
                }
              </span>
            ))}

            {menuContents && menuContents.length > 0
              && <Menu contents={menuContents} type={menuType}  />
            }

            <GourmetInfo
            showTableOnly
            title={article.title}
            caption={article.external_link_caption}
            url={article.external_link_url}
            />

            {profile.type
            && (
              <ArticleProfile
                name={profile.content}
                text={profile.content1}
                photo={profile.image}
              />
            )
            }

            {arrMap && arrMap[0]
              && (
                <div>
                  {arrMap[1]
                    ? (
                      <ArticleDropdown arrMap={arrMap} />
                    )
                    : (
                      <ArticleMap
                        address={arrMap[0].address}
                        latitude={arrMap[0].latitude}
                        longitude={arrMap[0].longitude}
                        chopeUrl={arrMap[0].chope_url}
                        // not need for now
                        // opening_hour={JSON.parse(arrMap[0].opening_hour)}
                        contact={arrMap[0].contact}
                      />
                    )
                  }
                </div>
              )
            }

          </ArticlePage>
        );
      },

    });

    return (<LoadableComponent />);
  }
};
