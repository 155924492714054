import lightBlue from '@material-ui/core/colors/lightBlue';
import red from '@material-ui/core/colors/red';
import breakPoints from 'configs/BreakpointsConfig';

export default {
    default    : {
        palette: {
            type     : 'light',
            secondary: {
                light: lightBlue[400],
                main : lightBlue[600],
                dark : lightBlue[700]
            },
            error    : red
        },
        status : {
            danger: 'orange'
        }
    },
    breakpoints: {
        values: {
            xs: breakPoints.xs,
            sm: breakPoints.sm,
            md: breakPoints.md,
            lg: breakPoints.lg,
          }
    },
    typography: {
    // Use the system font instead of the default Roboto font.
      fontFamily: [
        'Agenda',
        'Roboto',
        '-apple-system',
        'BlinkMacSystemFont',
        'Segoe UI',
        'Oxygen',
        'Ubuntu',
        'Cantarell',
        'Fira Sans',
        'Droid Sans',
        'Helvetica Neue',
        'sans-serif',
      ].join(','),
    },
    dark   : {
        palette: {
            type     : 'dark',
            primary  : {
                light: '#86fff7',
                main : '#4ecdc4',
                dark : '#009b94'
            },
            secondary: {
                light       : '#ff9d99',
                main        : '#ff6b6b',
                dark        : '#c73840',
                contrastText: '#fff'
            }
        }
    }
};
