import React, { Component } from 'react';
import { withStyles, Typography } from '@material-ui/core';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import * as QPActions from 'components/QuickPanel/store/actions/index';
import BreakpointsConfig from 'configs/BreakpointsConfig';

const styles = theme => ({
  root: {
    background: 'url(/assets/images/backgrounds/bookmark.png)',
    height: '50px',
    width: '50px',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    position: 'absolute',
    right: '20px',
    cursor: 'pointer',
  },
  favBtnRight: {
    width: '30px',
    height: '35px',
    [theme.breakpoints.up(BreakpointsConfig.md)]: {
      right: '65px',
      width: '40px',
      height: '45px',
    },
  },
});

class FavouritesCtrIcon extends Component {
  handleClick() {
    this.props.toggleQuickPanel(true, 1);
  }

  render() {
    const { classes, className, count } = this.props;

    return (
      <div className={classNames(classes.root, 'flex items-center justify-center', className, classes.favBtnRight)} onClick={this.handleClick.bind(this)}>

        <Typography className="text-18 font-sans font-bold">
          { (count && count > 0) ? count : '' }
        </Typography>
      </div>
    );
  }
}


function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    toggleQuickPanel: QPActions.toggleQuickPanel,
  }, dispatch);
}

function mapStateToProps({ favourites }) {
  return {
    count: favourites.count,
  };
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(FavouritesCtrIcon)));
