import React, { Component } from 'react';
import Loadable from 'react-loadable';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-external';
import { withRouter } from 'react-router-dom';
import Loading from 'components/Elements/Loading';
import classNames from 'classnames';
import api from 'configs/ApiConfig';

const styles = theme => ({
  root: {
    background: 'rgba(0,0,0,0.05)',
  },
  slickList: {
    maxWidth: 1420,
    margin: '0 auto',
    '& .slick-list': {
      zIndex: '2',
      [theme.breakpoints.up('md')]: {
        margin: '0 50px',
      },
    },
  },
  arrow: {
    position: 'absolute',
    top: '50%',
    transform: 'translate(0, -50%)',
    cursor: 'pointer',
    zIndex: '9999',
  },
});

const propTypes = {};
const defaultProps = {};

class DealsCarousel extends Component {
  render() {
    const {
      classes, Deals, Card, Slider, Previous, Next, origin,
    } = this.props;

    const PrevArrow = (props) => {
      const { onClick } = props;
      return (
        <div
          className={classNames(classes.arrow, 'bg-black p-12 pb-4 pin-l')}
          onClick={onClick}
        >
          <Previous size="medium" color="white" />
        </div>
      );
    };

    const NextArrow = (props) => {
      const { onClick } = props;
      return (
        <div
          className={classNames(classes.arrow, 'bg-black p-12 pb-4 pin-r')}
          onClick={onClick}
        >
          <Next size="medium" color="white" />
        </div>
      );
    };

    const pagerSettings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: Deals.length <= 4 ? Deals.length : 4,
      focusOnSelect: true,
      slidesToScroll: Deals.length <= 4 ? Deals.length : 4,
      swipeToSlide: true,
      centerMode: false,
      prevArrow: <PrevArrow />,
      nextArrow: <NextArrow />,
      responsive: [
        {
          breakpoint: 778,
          settings: {
            slidesToShow: Deals.length <= 2 ? Deals.length : 2,
            centerMode: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            centerMode: true,
          },
        },
      ],
    };

    return (
      <Slider
        {...pagerSettings}
      >
        {Deals.map(Deal => (
          <Card
            key={Deal.id}
            content={Deal}
            variant="contained"
            type="deals"
            category="deals"
            className="min-h-370"
            hideSocial
            subtitleKey="full_description"
            origin={origin}
          />
        ))}
      </Slider>
    );
  }
}

DealsCarousel.defaultProps = {
  origin: '',
}

class Deals extends Component {
  render() {
    const {
      classes, className, type, category, origin, title, subtitle, subtitleCollapse
    } = this.props;

    const LoadableComponent = Loadable.Map({
      loader: {
        data: () => api.get('/deals', { params: { size: 20 } }).then(res => res.data.data),
        headerText: () => import('components/Elements/HeaderText'),
        card: () => import('components/Cards/Card'),
        button: () => import('@material-ui/core/Button'),
        slider: () => import('react-slick'),
        slickCSS: () => import('slick-carousel/slick/slick.css'),
        slickTheme: () => import('slick-carousel/slick/slick-theme.css'),
        icons: () => import('grommet-icons'),
      },
      delay: 500,
      timeout: 10000,
      loading: props => <Loading {...props} />,
      render: (loaded, props) => {
        const HeaderText = loaded.headerText.default;
        const Card = loaded.card.default;
        const Slider = loaded.slider.default;
        const Button = loaded.button.default;
        const Previous = loaded.icons.Previous;
        const Next = loaded.icons.Next;

        return (
          <div className={classNames(classes.root, className)}>
            <div className={classNames(classes.slickList, 'py-32 w-full')}>
              <HeaderText
                small
                title={title || "Latest Deals"}
                subtitle={subtitle || "You don't have to go for the cheapest thing on the menu. <br>Use your UOB Card for deals and discounts <br> on Singapore’s most delectable dishes."}
                subtitleCollapse={subtitleCollapse}
              />
              <DealsCarousel
                {...props}
                Deals={loaded.data}
                Card={Card}
                Slider={Slider}
                category={category}
                origin={origin}
                type={type}
                Next={Next}
                Previous={Previous}
              />
              <div className="flex justify-center items-center my-32">
                <Button
                  component={Link}
                  className="my-12 tracking-wide p-12 pb-8 px-16  uppercase leading-none border-3 border-solid border-grey-darkest font-bold lg:text-18 sm:text-14 xs:text-12 text-grey-darkest no-underline button-outlined-black relative"
                  to="/deals"
                >
                  <span className="block">
                    All Deals
                    <span
                      className="block absolute w-full h-full pin-t pin-l"
                      data-track={'home >> all deals'}
                    />
                  </span>
                </Button>
              </div>
            </div>
          </div>
        );
      },
    });

    return (<LoadableComponent {...this.props} />);
  }
}


Deals.propTypes = propTypes;
Deals.defaultProps = defaultProps;

export default withStyles(styles, { withTheme: true })(withRouter(Deals));
