import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as Actions from 'store/actions';
import {
    AppBar,
    Paper,
    Hidden,
    Grid,
    IconButton,
    Toolbar,
    MuiThemeProvider,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import classNames from 'classnames';
import * as quickPanelActions from 'components/QuickPanel/store/actions';
import * as searchPanelActions from 'components/Search/store/actions';
import MenuIcon from 'react-hamburger-menu';
import FavouritesCtrIcon from 'components/Favourites/FavouritesCtrIcon';
import SearchInput from 'components/Search';
import { Link } from 'react-router-external';
import BreakpointsConfig from 'configs/BreakpointsConfig';
import MainNavigation from './MainNavigation';
import Button from '@material-ui/core/Button';

import api from 'configs/ApiConfig';

const defaultProps = {};

const navbarWidth = 256;

const styles = theme => ({
    btnSearchMb: {
        minWidth: 48,
    },
    root: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        height: '100%',
        overflowY: 'hidden',
        // backgroundColor: 'green',
    },
    gutters: theme.mixins.gutters({
        [theme.breakpoints.up('sm')]: {
            paddingLeft: theme.spacing.unit * 2,
            paddingRight: theme.spacing.unit * 2,
        },
    }),
    content: {
        display: 'flex',
        overflow: 'auto',
        flex: '1 1 auto',
        flexDirection: 'column',
        width: '100%',
        '-webkit-overflow-scrolling': 'touch',
        zIndex: 4,
    },
    hamburger: {
        '&:hover': {
            backgroundColor: 'transparent !important',
        },
    },
    navbarWrapper: {
        flex: 'none',
        boxShadow: 'none',
        borderBottom: '1px solid #e5e5e5',
        zIndex: theme.zIndex.drawer + 200,
    },
    navbar: {
        display: 'flex',
        height: 64,
        alignItems: 'center',
    },
    NavGroup: {
        minHeight: 30
    },
    navbarMobile: {
        display: 'flex',
        overflow: 'hidden',
        flexDirection: 'column',
        width: navbarWidth,
        minWidth: navbarWidth,
        height: '100%',
        transition: theme.transitions.create(['width', 'min-width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.shorter,
        }),
    },
    navbarButton: {
        '&.right': {
            borderLeft: `1px solid ${theme.palette.divider}`,
        },
        '&.left': {
            borderRight: `1px solid ${theme.palette.divider}`,
        },
    },
    navbarHeaderWrapper: {
        display: 'flex',
        alignItems: 'center',
        flex: '0 0 auto',
        width: 'auto',
        height: '100%',
        minWidth: 0,
        flexDirection: 'row',
        zIndex: theme.zIndex.drawer + 200,
        [theme.breakpoints.down(BreakpointsConfig.md)]: {
            flex: '0 1 auto',
            height: 64,
            minHeight: 64,
        },
    },
    navbarHeader: {
        width: '100%',
        // height: '100%',
    },
    [theme.breakpoints.up(BreakpointsConfig.lg)]: {
        navbarHeader: {
            display: 'flex',
            flex: '1 0 auto',
            padding: '10px 8px 10px 16px',
        },
    },
    [theme.breakpoints.up(BreakpointsConfig.md)]: {
        navbarHeader: {
            padding: '18px 0 18px 16px',
        },
    },
    brandContent: {
        flex: '1 1',
    },
    navbarContent: {
        minWidth: 0,
        [theme.breakpoints.down(BreakpointsConfig.md)]: {
            overflowX: 'hidden',
            overflowY: 'auto',
            //  background          : 'linear-gradient(rgba(0, 0, 0, 0) 30%, rgba(0, 0, 0, 0) 30%), linear-gradient(rgba(0, 0, 0, 0.25) 0, rgba(0, 0, 0, 0) 40%)',
            backgroundRepeat: 'no-repeat',
            backgroundSize: '100% 40px, 100% 10px',
            backgroundAttachment: 'local, scroll',
        },
        '-webkit-overflow-scrolling': 'touch',
    },
    logo: {
        // backgroundColor: 'blue',
    },
    logoImage: {
        width: 220, // 238,
        // height: 42,
        maxWidth: '100%',
        // backgroundColor: 'red',
        transition: theme.transitions.create(['width', 'height'], {
            duration: theme.transitions.duration.shortest,
            easing: theme.transitions.easing.easeInOut,
        }),
    },
    favBtnTop: {
        top: '100%',
    },
    navOpen: {
        padding: '16px 10px',
        backgroundColor: '#666666',
        borderRadius: 0,
        '& .icon-close': {
            color: '#fff',
        },
        '&:hover': {
            backgroundColor: '#666666',
        }
    },
    sticky: {
        backgroundColor: '#159587',
        color: '#fff',
        padding: '5px 5px 5px 15px',
        textAlign: 'center',
        fontSize: '18px',
    },
    stickyWrapper: {
        position: 'relative',
        // maxWidth: '900px',
        marginLeft: 'auto',
        marginRight: 'auto',
        paddingRight: '30px',
        paddingLeft: '30px',
    },
    stickyTitle: {
        fontWeight: '700',
    },
    stickyIcon: {
        height: '18px',
        width: '18px',
        paddingRight: '5px',
        position: 'relative',
        top: '2px',
    },
    closeButton: {
        position: 'absolute',
        top: '-5px',
        right: 0,
        minWidth: 0,
        padding: '8px',
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
});

class MainHeader extends Component {

    constructor(props) {
        super(props);
        this.state = {
            whatsonActive: 0,
        };
    }

    componentDidMount() {
        // const { whatsonActive } = this.state;

        api.get(`/whatson-homepage`, {}).then((response) => {
            this.setState({
                whatsonActive: response.data.data[0].active,
            });

        });
    }


    togglePanel = () => {
        const { toggleQuickPanel, qpState } = this.props;
        toggleQuickPanel(!qpState);
    }

    closeSticky = () => {
        // console.log("Close Sticky");
        const stickyContainer = document.getElementsByClassName('sticky')[0];
        // stickyContainer.parentNode.removeChild(stickyContainer);
        stickyContainer.classList.add("hidden");
        sessionStorage.setItem('stickyInfo', 0);
    }


    render() {
        const { classes, qpState, spState, isFocusSearch, searchRef, toggleSearchPanel, location } = this.props;

        const { whatsonActive } = this.state;

        // const stickyRegex = /\/deals/g;

        /* let stickyEnabled = location.pathname.search("/deals");

        let stickyEnabledhome = location.pathname.search("/home");

        if (sessionStorage.getItem('stickyInfo') == 0) {
            stickyEnabled = -1;
            stickyEnabledhome = -1;
        } */

        const logo = (
            <Toolbar classes={{ gutters: classes.gutters }}>
                <div className={classes.navbarHeader}>
                    <div className={classes.root}>
                        <Link
                            className={classNames(
                                classes.logo,
                                'flex items-center cursor-pointer no-underline',
                            )}
                            to="/"
                        >
                            <img
                                data-track={'nav bar >> home'}
                                className={classNames(classes.logoImage, 'logo-image')}
                                src="/assets/images/logos/logo.png"
                                alt="logo"
                            />
                        </Link>
                    </div>
                </div>
            </Toolbar>
        );

        return (
            <MuiThemeProvider>
                <Paper className={classes.navbarWrapper} square>
                    <AppBar color="white" position="relative" elevation={0} data-header className="transition-scroll">

                        {/* {stickyEnabled != -1 &&
                            <div className={classNames(classes.sticky, 'sticky')}>
                                <div className={classes.stickyWrapper}>
                                    <div className={classes.stickyTitle}>
                                        <span>
                                            <svg className={classes.stickyIcon} aria-hidden="true" focusable="false" data-prefix="far" data-icon="lightbulb" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 352 512"><path fill="currentColor" d="M176 80c-52.94 0-96 43.06-96 96 0 8.84 7.16 16 16 16s16-7.16 16-16c0-35.3 28.72-64 64-64 8.84 0 16-7.16 16-16s-7.16-16-16-16zM96.06 459.17c0 3.15.93 6.22 2.68 8.84l24.51 36.84c2.97 4.46 7.97 7.14 13.32 7.14h78.85c5.36 0 10.36-2.68 13.32-7.14l24.51-36.84c1.74-2.62 2.67-5.7 2.68-8.84l.05-43.18H96.02l.04 43.18zM176 0C73.72 0 0 82.97 0 176c0 44.37 16.45 84.85 43.56 115.78 16.64 18.99 42.74 58.8 52.42 92.16v.06h48v-.12c-.01-4.77-.72-9.51-2.15-14.07-5.59-17.81-22.82-64.77-62.17-109.67-20.54-23.43-31.52-53.15-31.61-84.14-.2-73.64 59.67-128 127.95-128 70.58 0 128 57.42 128 128 0 30.97-11.24 60.85-31.65 84.14-39.11 44.61-56.42 91.47-62.1 109.46a47.507 47.507 0 0 0-2.22 14.3v.1h48v-.05c9.68-33.37 35.78-73.18 52.42-92.16C335.55 260.85 352 220.37 352 176 352 78.8 273.2 0 176 0z"></path></svg>
                                        </span>
                                        Flattening The Curve
                                    </div>
                                    As we all do our part to stay home and to stay safe, we encourage you to call or visit their websites to view the operating details of our merchant partners.
                                    <Button className={`${classes.closeButton} btn-close`} onClick={this.closeSticky} >
                                        <img src="/assets/images/close-btn.png" alt="close" />
                                    </Button>
                                </div>
                            </div>

                        } */}
                       {/*  {stickyEnabledhome != -1 &&
                            <div className={classNames(classes.sticky, 'sticky')}>
                                <div className={classes.stickyWrapper}>
                                    <div className={classes.stickyTitle}>
                                        <span>
                                            <svg className={classes.stickyIcon} aria-hidden="true" focusable="false" data-prefix="far" data-icon="lightbulb" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 352 512"><path fill="currentColor" d="M176 80c-52.94 0-96 43.06-96 96 0 8.84 7.16 16 16 16s16-7.16 16-16c0-35.3 28.72-64 64-64 8.84 0 16-7.16 16-16s-7.16-16-16-16zM96.06 459.17c0 3.15.93 6.22 2.68 8.84l24.51 36.84c2.97 4.46 7.97 7.14 13.32 7.14h78.85c5.36 0 10.36-2.68 13.32-7.14l24.51-36.84c1.74-2.62 2.67-5.7 2.68-8.84l.05-43.18H96.02l.04 43.18zM176 0C73.72 0 0 82.97 0 176c0 44.37 16.45 84.85 43.56 115.78 16.64 18.99 42.74 58.8 52.42 92.16v.06h48v-.12c-.01-4.77-.72-9.51-2.15-14.07-5.59-17.81-22.82-64.77-62.17-109.67-20.54-23.43-31.52-53.15-31.61-84.14-.2-73.64 59.67-128 127.95-128 70.58 0 128 57.42 128 128 0 30.97-11.24 60.85-31.65 84.14-39.11 44.61-56.42 91.47-62.1 109.46a47.507 47.507 0 0 0-2.22 14.3v.1h48v-.05c9.68-33.37 35.78-73.18 52.42-92.16C335.55 260.85 352 220.37 352 176 352 78.8 273.2 0 176 0z"></path></svg>
                                        </span>
                                        Flattening The Curve
                                    </div>
                                    As we all do our part to stay home and to stay safe, we encourage you to call or visit their websites to view the operating details of our merchant partners.
                                    <Button className={`${classes.closeButton} btn-close`} onClick={this.closeSticky} >
                                        <img src="/assets/images/close-btn.png" alt="close" />
                                    </Button>
                                </div>
                            </div>

                        } */}
                        <Hidden smDown>
                            <Toolbar>
                                <div className="container relative">
                                    <div className={classNames(classes.navbar)}>
                                        <div className={classes.brandContent}>{logo}</div>
                                        <div className={classNames(classes.navbarContent)}>
                                            {!isFocusSearch &&
                                                <MainNavigation
                                                    whatson={whatsonActive}
                                                />
                                            }
                                        </div>
                                        <div className="flex self-stretch border-l-1 border-r-1 border-grey p-2 ">
                                            <SearchInput searchRef={searchRef} />
                                        </div>
                                        <div className="flex border-l-1 self-stretch border-grey-lighter p-2">
                                            <IconButton
                                                className={classNames(classes.hamburger, 'w-64 h-64')}
                                                onClick={this.togglePanel}
                                            >
                                                <MenuIcon
                                                    menuClicked={() => { }}
                                                    isOpen={qpState}
                                                    width={22}
                                                    height={13}
                                                    strokeWidth={2}
                                                    rotate={0}
                                                    color="black"
                                                    borderRadius={0}
                                                    animationDuration={0.3}
                                                />
                                            </IconButton>
                                        </div>
                                    </div>
                                    <FavouritesCtrIcon />
                                </div>
                            </Toolbar>
                        </Hidden>

                        <Hidden mdUp>
                            <Toolbar disableGutters className="border-b-1 border-grey">
                                <Grid
                                    container
                                    direction="row"
                                    justify="betwwen"
                                    alignItems="center"
                                    className="justify-between"
                                >
                                    <Grid item>
                                        <IconButton onClick={() => toggleSearchPanel()} className={classNames(classes.btnSearchMb, spState ? classes.navOpen : '')}>
                                            {
                                                spState ?
                                                    <CloseIcon className="icon-close" /> :
                                                    <img src="/assets/images/search-icon.svg" alt="search icon" style={{ maxWidth: '18px' }} className="icon-search" data-tracka="Recommender >> search" />
                                            }
                                        </IconButton>
                                    </Grid>
                                    <Grid item>{logo}</Grid>
                                    <Grid item>
                                        <IconButton onClick={this.togglePanel}>
                                            {
                                                spState ?
                                                    <div style={{ width: '22px' }} /> :
                                                    <MenuIcon
                                                        menuClicked={() => { }}
                                                        isOpen={qpState}
                                                        width={22}
                                                        height={13}
                                                        strokeWidth={2}
                                                        rotate={0}
                                                        color="black"
                                                        borderRadius={0}
                                                        animationDuration={0.3}
                                                    />
                                            }
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            </Toolbar>
                            <Toolbar className={classes.NavGroup}>
                                <Grid
                                    container
                                    direction="row"
                                    justify="center"
                                    alignItems="center"
                                >
                                    {!isFocusSearch &&
                                        <MainNavigation
                                            whatson={whatsonActive}
                                        />
                                    }
                                </Grid>
                            </Toolbar>
                            <FavouritesCtrIcon className={classes.favBtnTop} />
                        </Hidden>
                    </AppBar>
                </Paper>
            </MuiThemeProvider>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            getSearchResults: Actions.getSearchResults,
            toggleQuickPanel: quickPanelActions.toggleQuickPanel,
            toggleSearchPanel: searchPanelActions.toggleSearchPanel,
        },
        dispatch,
    );
}

function mapStateToProps({ scroll, quickPanel, search, searchPanel }) {
    return {
        qpState: quickPanel.state,
        spState: searchPanel.state,
        scroll: scroll.data,
        isFocusSearch: search.isFocus,
    };
}

MainHeader.propTypes = {
    classes: PropTypes.string,
    qpState: PropTypes.bool,
    toggleQuickPanel: PropTypes.func,
    spState: PropTypes.bool,
};

MainHeader.defaultProps = defaultProps;

export default withStyles(styles, { withTheme: true })(
    withRouter(
        connect(
            mapStateToProps,
            mapDispatchToProps,
        )(MainHeader),
    ),
);
