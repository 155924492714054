import * as Actions from '../actions';

const initialState = {
  state: false,
  tab: 0,
};

const quickPanel = function (state = initialState, action) {
  switch (action.type) {
    case Actions.TOGGLE_QUICK_PANEL:
    {
      return {
        ...state,
        state: action.payload === null ? !state.state : action.payload,
        tab: action.tab,
      };
    }
    default:
    {
      return state;
    }
  }
};

export default quickPanel;
